import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { remark } from 'remark';
import remarkHtml from 'remark-html';
import rehypeParse from 'rehype-parse';
import rehypeRemark from 'rehype-remark';
import remarkStringify from 'remark-stringify';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  editor: {
    '& .ql-editor': {
      minHeight: '50vh',
      BorderAllRounded: '4px',
      fontFamily: theme.typography.fontFamily,
    },
  },
}));

const markdownToHtml = (markdown) => {
  const file = remark().use(remarkHtml).processSync(markdown);
  return file.toString();
};

const htmlToMarkdown = (html) => {
  const file = remark()
    .use(rehypeParse)
    .use(rehypeRemark)
    .use(remarkStringify)
    .processSync(html);

  return file.toString();
};

/**
 * Basic text editor using Quill.
 * Uses only features that are markdown compatible.
 * Outputs markdown as well as html.
 */
const RichTextEditor = (props) => {
  const {
    field, form, placeholder, sx, autoSave,
  } = props;
  const [value, setValue] = useState(field.value ? markdownToHtml(field.value) : '');
  const ReactQuillRef = React.createRef(null);
  const classes = useStyles();

  useEffect(() => {
    setValue(field.value ? markdownToHtml(field.value) : '');
  }, [field.value]);

  const handleChange = (content) => {
    setValue(content);
    const markdownContent = htmlToMarkdown(content);
    form.setFieldValue(field.name, markdownContent);

    if (autoSave) {
      autoSave(content);
    }
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ header: [1, 2, 3, 4] }],
    ],
  };

  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'header',
  ];

  return (
    <Box sx={sx}>
      <ReactQuill
        ref={ReactQuillRef}
        className={clsx(classes.editor)}
        theme="snow"
        modules={modules}
        formats={formats}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
      />
    </Box>
  );
};

export default RichTextEditor;
