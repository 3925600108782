import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';

const AudioRecorder = forwardRef(({
    onStartRecording,
    onStopRecording,
    onDataAvailable,
    maxRecordingTime = 30000, // 30 seconds default
    silenceThreshold = 0.01,  // Adjusted for normalized data
    silenceDuration = 5000,   // 5 seconds default
    silenceDetectionDelay = 2000, // 2 seconds grace period before starting silence detection
}, ref) => {
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const recordingTimeoutRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const silenceStartRef = useRef(null);
    const silenceDetectionIntervalRef = useRef(null);
    const silenceDetectionStartTimeRef = useRef(null);

    const handleStartRecording = async () => {
        if (isRecording) return;

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContextRef.current.createMediaStreamSource(stream);
            analyserRef.current = audioContextRef.current.createAnalyser();
            source.connect(analyserRef.current);
            analyserRef.current.fftSize = 2048;
            dataArrayRef.current = new Uint8Array(analyserRef.current.fftSize);

            mediaRecorderRef.current = new MediaRecorder(stream);
            audioChunks.current = [];
            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunks.current.push(event.data);
            };
            mediaRecorderRef.current.onstop = async () => {
                setIsRecording(false);
                cleanup();

                const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
                audioChunks.current = [];

                const isSilent = await checkIfSilent(audioBlob);
                if (isSilent) {
                    if (onDataAvailable) {
                        onDataAvailable(null);
                    }
                    return;
                }

                if (onDataAvailable) {
                    onDataAvailable(audioBlob);
                }
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
            if (onStartRecording) onStartRecording();

            silenceStartRef.current = null;
            silenceDetectionStartTimeRef.current = Date.now() + silenceDetectionDelay;

            silenceDetectionIntervalRef.current = setInterval(checkSilence, 500); // Adjust as needed
            recordingTimeoutRef.current = setTimeout(handleStopRecording, maxRecordingTime);
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    };

    const handleStopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            console.log('Stopping recording...');
            mediaRecorderRef.current.stop();
            if (onStopRecording) onStopRecording();
        }
    };

    const checkSilence = () => {
        // Delay the start of silence detection by silenceDetectionDelay milliseconds
        if (Date.now() < silenceDetectionStartTimeRef.current) {
            return; // Skip silence detection during the grace period
        }

        analyserRef.current.getByteTimeDomainData(dataArrayRef.current);
        let sum = 0;
        for (let i = 0; i < dataArrayRef.current.length; i++) {
            const value = (dataArrayRef.current[i] - 128) / 128; // Normalize to [-1, 1]
            sum += value * value;
        }
        const rms = Math.sqrt(sum / dataArrayRef.current.length);
        const volume = rms;

        // Debugging: Log the volume to see its value during speech and silence
        // console.log('Volume:', volume);

        if (volume < silenceThreshold) {
            if (silenceStartRef.current === null) {
                silenceStartRef.current = Date.now();
            } else if (Date.now() - silenceStartRef.current > silenceDuration) {
                console.log('Silence detected, stopping recording...');
                handleStopRecording();
            }
        } else {
            silenceStartRef.current = null;
        }
    };

    const checkIfSilent = (audioBlob) => {
        return new Promise((resolve) => {
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                const arrayBuffer = fileReader.result;
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
                    const rawData = audioBuffer.getChannelData(0);
                    let sum = 0;
                    for (let i = 0; i < rawData.length; i++) {
                        sum += rawData[i] * rawData[i];
                    }
                    const rms = Math.sqrt(sum / rawData.length);
                    audioContext.close();
                    resolve(rms < silenceThreshold);
                });
            };
            fileReader.readAsArrayBuffer(audioBlob);
        });
    };

    const cleanup = () => {
        clearInterval(silenceDetectionIntervalRef.current);
        clearTimeout(recordingTimeoutRef.current);
        if (audioContextRef.current) {
            if (audioContextRef.current.state !== 'closed') {
                console.log('Closing AudioContext...');
                audioContextRef.current.close();
            } else {
                console.warn("Attempted to close AudioContext, but it's already closed.");
            }
        }
    };

    useImperativeHandle(ref, () => ({
        startRecording: handleStartRecording,
        stopRecording: handleStopRecording,
        isRecording,
    }));

    useEffect(() => {
        return () => {
            handleStopRecording();
            cleanup();
        };
    }, []);

    return null;
});

export default AudioRecorder;
