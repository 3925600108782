import React from 'react';
import {
  Button,
  Avatar,
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LogoutIcon from '@mui/icons-material/Logout';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { greyboxApiActions } from '../redux/api';
import { logout } from '../redux/authorization';
import { toggleTheme } from '../redux/theme';

const ProfileButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const { style } = useSelector((state) => state.theme);

  const { user } = greyboxApiActions;
  const { data, isLoading } = user.get();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const userName = React.useMemo(() => {
    if (isLoading) return '';
    const firstInitial = data?.first_name ? data.first_name[0] : '';
    const lastInitial = data?.last_name ? data.last_name[0] : '';
    return `${firstInitial}${lastInitial}`;
  }, [isLoading, data]);

  const handleNavigation = React.useCallback(
    (path) => {
      setAnchorEl(null);
      navigate(path);
    },
    [navigate],
  );

  const handleLogout = React.useCallback(() => {
    dispatch(logout());
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
  }, [dispatch, instance]);

  const handleToggleTheme = React.useCallback(() => {
    dispatch(toggleTheme());
    setAnchorEl(null);
  }, [dispatch]);

  const handleOpenTerms = React.useCallback(() => {
    window.open('https://greybox.ca/terms', '_blank');
    setAnchorEl(null);
  }, []);

  const handleOpenSupportPortal = React.useCallback(() => {
    window.open('https://greybox.atlassian.net/servicedesk/customer/portals', '_blank'); // Replace with actual URL
    setAnchorEl(null);
  }, []);

  const menuItems = [
    {
      text: t('Your Information'),
      icon: <SettingsIcon fontSize="small" />,
      onClick: () => handleNavigation('/your-information'),
    },
    {
      text: t('Terms of Use'),
      icon: <AssignmentIcon fontSize="small" />,
      onClick: handleOpenTerms,
    },
    {
      text: style === 'light' ? t('Dark theme') : t('Light theme'),
      icon:
        style === 'light' ? (
          <DarkModeIcon fontSize="small" />
        ) : (
          <LightModeIcon fontSize="small" />
        ),
      onClick: handleToggleTheme,
    },
    {
      text: t('Support Portal'),
      icon: <SupportAgentIcon fontSize="small" />,
      onClick: handleOpenSupportPortal,
    },
    {
      text: t('Logout'),
      icon: <LogoutIcon fontSize="small" />,
      onClick: handleLogout,
    },
  ];

  return (
    <>
      <Button
        endIcon={<SettingsIcon sx={{ mr: 1 }} />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant="contained"
        sx={{
          borderRadius: '50px',
          p: 0.5,
        }}
      >
        <Avatar
          sx={{
            mr: 1,
            fontWeight: 'normal',
            fontSize: '0.9rem',
            bgcolor: (theme) => theme.palette.secondary.main,
            color: (theme) => theme.palette.secondary.contrastText,
            height: '30px',
            width: '30px',
          }}
          src={data?.profile_picture_url}
        >
          {userName}
        </Avatar>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList>
          {menuItems.map((item, index) => (
            <MenuItem key={index} onClick={item.onClick}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.text}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

export default ProfileButton;
