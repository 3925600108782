import React, { useRef, useState, useEffect, useMemo } from 'react';
import '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import iCalendarPlugin from '@fullcalendar/icalendar';
import timeGridPlugin from '@fullcalendar/timegrid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import interactionPlugin from '@fullcalendar/interaction';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import TodayIcon from '@mui/icons-material/Today';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import ShareIcon from '@mui/icons-material/Share';
import {
  Paper, Typography, Box, Button, ButtonGroup, Popper, Grow, ClickAwayListener,
  MenuList, MenuItem, ToggleButtonGroup, ToggleButton, IconButton, Tooltip,
  useMediaQuery,
} from '@mui/material';
import allLocales from '@fullcalendar/core/locales-all';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import AddEventModal from './AddEventModal';
import { domainConfigs, greyboxApiActions } from '../../../redux/api';
import IcsLinkDialog from './IcsLinkDialog';

const CalendarButton = (props) => {
  const {
    setOpen,
    ref,
  } = props;

  return (
    <IconButton ref={ref} size="small" onClick={(e) => setOpen(e.currentTarget)}>
      <ArrowDropDownIcon />
    </IconButton>
  );
};

/**
 * Calendar used to display and show events for a patient.
 * Add an event by clicking on a day. The event wil be POSTED to be generate in an ics
 * from backend.
 */
const PatientCalendar = () => {
  const { t, i18n } = useTranslation();
  const { calendar } = greyboxApiActions;
  const { uuid } = useParams();
  const { clinic } = useSelector((state) => state.clinic);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedType, setSelectedType] = useState(0);
  const [typeOpen, setTypeOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [calendarTitle, setCalendarTitle] = useState(null);
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [isAllDay, setIsAllDay] = useState(false);
  const [view, setView] = useState('timeGridWeek');
  const [anchorEl, setAnchorEl] = useState(null);
  const [icsLinkOpen, setIcsLinkOpen] = useState(false);
  const { data, isLoading } = calendar.list({ owner: uuid, clinic: clinic.id });
  const calendarRef = useRef(null);
  const anchorRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const EVENT_TYPES = [
    {
      id: 'Event',
      label: t('Event'),
    },
    {
      id: 'QuestionnaireEvent',
      label: t('Questionnaire'),
    },
    {
      id: 'OnlineMeetingEvent',
      label: t('Online Meeting'),
    },
  ];

  const handleMenuItemClick = (event, index) => {
    event.stopPropagation();
    setSelectedType(index);
    setTypeOpen(false);
    handleOpenEventModal();
  };

  const handleOpenEventModal = () => {
    setSelectedDate(moment());
    setIsAllDay(false);
    setSelectedEventId(null);
    setEventModalOpen(true);
  };

  const handleToggle = () => {
    setTypeOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setTypeOpen(false);
  };

  const handleDateChange = (date) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(moment(date).toDate());
      updateCalendarTitle();
      setAnchorEl(null); 
    }
  };

  const refetchEvents = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.refetchEvents();
  };

  const renderEventContent = (eventInfo) => (
    <Box sx={{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: '100%',
    }}
    >
      <Typography
        variant="caption"
        sx={{ mx: 0.5 }}
        color={(theme) => (view === 'timeGridWeek' ? theme.palette.primary.contrastText : theme.palette.text.secondary)}
        noWrap
      >
        {eventInfo.event.title}
      </Typography>
    </Box>
  );

  const handlePrev = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.prev();
      updateCalendarTitle();
    }
  };

  const handleNext = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.next();
      updateCalendarTitle();
    }
  };

  const handleChangeView = (viewName) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      setView(viewName);
      calendarApi.changeView(viewName);
      updateCalendarTitle();
    }
  };

  const handleToday = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.today();
      updateCalendarTitle();
    }
  };

  useEffect(() => {
    updateCalendarTitle();
  }, []);

  const updateCalendarTitle = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      setCalendarTitle(calendarApi.view.title);
    }
  };

  useEffect(() => {
    if (calendarRef.current) {
      updateCalendarTitle();
    }
  }, [calendarRef.current]);

  const key = data?.[0]?.secret_key;
  const fullCalendar = useMemo(() => {
    return <FullCalendar
      initialView="timeGridWeek"
      ref={calendarRef}
      plugins={[dayGridPlugin, iCalendarPlugin, timeGridPlugin, interactionPlugin]}
      events={{
        url: `https://${domainConfigs[window.location.hostname]}/calendar/${key}/takecare.ics`,
        format: 'ics',
      }}
      eventContent={renderEventContent}
      eventClick={(info) => {
        info.jsEvent.preventDefault();
        setSelectedEventId(info.event.extendedProps.organizer);
        setEventModalOpen(true);
      }}
      height="100%"
      locale={i18n.resolvedLanguage}
      locales={allLocales}
      headerToolbar={{
        left: '',
        center: '',
        right: '',
      }}
      dateClick={(info) => {
        setSelectedDate(moment(info.date));
        setIsAllDay(info.allDay);
        setEventModalOpen(true);
      }}
    />
  }, [key]);

  if (isLoading || !data || data.length === 0) {
    return null;
  }

  const calendarUrl = `${domainConfigs[window.location.hostname]}/calendar/${data[0].secret_key}/takecare-questionnaires.ics`

  return (
    <Box width="100%">
      <Paper sx={{
        p: 2,
        fontFamily: (theme) => theme.typography.fontFamily,
        height: '100vh',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      >
        <Box display="flex">
          <ButtonGroup sx={{ mr: 1 }}>
            <Tooltip title={t('Today')}>
              <Button
                onClick={handleToday}
                size="small"
              >
                <TodayIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t('Previous')}>
              <Button
                size="small"
                onClick={handlePrev}
              >
                <ArrowLeftIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t('Next')}>
              <Button
                size="small"
                onClick={handleNext}
              >
                <ArrowRightIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
          <ButtonGroup ref={anchorRef} aria-label="split button" data-cy="add-event-button">
            <Button onClick={handleOpenEventModal} startIcon={<AddIcon />}>
              {t('Event')}
            </Button>
            <Button
              size="small"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1001,
            }}
            open={typeOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {EVENT_TYPES.map((option, index) => (
                        <MenuItem
                          key={option.id}
                          selected={index === selectedType}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <IconButton onClick={() => setIcsLinkOpen(!icsLinkOpen)} sx={{ ml: 1, border: (theme) => `1px solid ${theme.palette.primary.main}` }}>
            <ShareIcon />
          </IconButton>
          {calendarTitle && (
            <Box width="100%" display="flex" alignItems="center" justifyContent="center">
              <Typography variant="h6" onClick={(e) => setAnchorEl(e.currentTarget)} style={{ cursor: 'pointer' }}>
                {calendarTitle}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  slots={{ field: CalendarButton }}
                  slotProps={{ field: { setOpen: setAnchorEl }, popper: { anchorEl } }}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  onAccept={handleDateChange}
                  value={selectedDate || null}
                />
              </LocalizationProvider>
            </Box>
          )}
          <ToggleButtonGroup variant="contained" sx={{ ml: 'auto', mr: 1 }} value={view} exclusive size="small">
            <ToggleButton
              data-cy="month-view-button"
              onClick={() => handleChangeView('dayGridMonth')}
              value="dayGridMonth"
            >
              {t('Month')}
            </ToggleButton>
            <ToggleButton onClick={() => handleChangeView('timeGridWeek')} value="timeGridWeek">
              {t('Week')}
            </ToggleButton>
            <ToggleButton onClick={() => handleChangeView('dayGridDay')} value="dayGridDay">
              {t('Day')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {fullCalendar}
      </Paper>
      {eventModalOpen && (
        <AddEventModal
          open={eventModalOpen}
          eventId={selectedEventId}
          date={selectedDate}
          isAllDay={isAllDay}
          selectedType={EVENT_TYPES[selectedType]}
          refetchEvents={refetchEvents}
          handleClose={() => {
            setEventModalOpen(false);
            setSelectedEventId(null);
            setSelectedDate(null);
            setSelectedType(0);
            setIsAllDay(false);
          }}
        />
      )}
      {icsLinkOpen && (
        <IcsLinkDialog open={icsLinkOpen} onClose={() => setIcsLinkOpen(false)} calendarUrl={calendarUrl} />
      )}
    </Box>

  );
};

export default PatientCalendar;
