// AiInstructionInputField.js
import React, { useState, useRef } from 'react';
import { TextField, IconButton, InputAdornment, Box, CircularProgress, Alert } from '@mui/material';
import { LuSparkles } from "react-icons/lu";
import AudioIcon from '@mui/icons-material/KeyboardVoice';
import StopIcon from '@mui/icons-material/Stop';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../../redux/api';
import { useFormikContext } from 'formik';
import { processDosage } from './utils';
import { useTheme } from '@mui/material/styles';
import AudioRecorder from '../../../audio/AudioRecorder';

const AiInstructionInputField = ({ medication_input }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { convertDosage } = greyboxApiActions;
    const [postConvertDosage] = convertDosage.add();
    const [inputValue, setInputValue] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const { setValues, values } = useFormikContext();

    const audioRecorderRef = useRef(null);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSendClick = async () => {
        setIsLoading(true);
        setError('');
        try {
            const result = await postConvertDosage({ body: { medication_input, dosage_input: inputValue } });
            const newDosage = processDosage(result.data);
            setValues({
                ...values,
                ...newDosage,
                doseValue: newDosage.doseValue || values.doseValue,
                doseUnit: newDosage.doseUnit || values.doseUnit,
            });
            setInputValue('');
        } catch (error) {
            console.error('Error converting dosage:', error);
            setError(t('An error occurred while converting the dosage. Please try again.'));
        } finally {
            setIsLoading(false);
        }
    };

    const handleAudioClick = () => {
        if (isRecording) {
            audioRecorderRef.current.stopRecording();
        } else {
            audioRecorderRef.current.startRecording();
        }
    };

    const handleStartRecording = () => {
        setIsRecording(true);
    };

    const handleStopRecording = () => {
        setIsRecording(false);
    };

    const handleAudioDataAvailable = async (audioBlob) => {
        if (!audioBlob) {
            alert(t('No sound detected in the recording.'));
            return;
        }

        setIsLoading(true);
        setError('');
        const formData = new FormData();
        formData.append('medication_input', medication_input);
        formData.append('audio_file', audioBlob, 'recording.wav');

        try {
            const result = await postConvertDosage({ body: formData });
            const newDosage = processDosage(result.data);

            setValues({
                ...values,
                ...newDosage,
            });
        } catch (error) {
            console.error('Error converting dosage:', error);
            setError(t('An error occurred while converting the dosage from audio. Please try again.'));
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendClick();
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {error && (
                <Alert severity="error" sx={{ mb: 1 }}>
                    {error}
                </Alert>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    fullWidth
                    multiline
                    placeholder={t('AI powered dosage instructions')}
                    variant="outlined"
                    value={inputValue}
                    disabled={isLoading}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LuSparkles color={theme.palette.text.primary} size={24} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    color="primary"
                                    onClick={
                                        inputValue && !isLoading
                                            ? handleSendClick
                                            : handleAudioClick
                                    }
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <CircularProgress size={24} />
                                    ) : inputValue ? (
                                        <SendIcon />
                                    ) : isRecording ? (
                                        <StopIcon />
                                    ) : (
                                        <AudioIcon />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: '4px',
                    }}
                />
            </Box>
            <AudioRecorder
                ref={audioRecorderRef}
                onStartRecording={handleStartRecording}
                onStopRecording={handleStopRecording}
                onDataAvailable={handleAudioDataAvailable}
            />
        </Box>
    );
};

export default AiInstructionInputField;
