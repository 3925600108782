import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import ObservationChart from '../chart/ObservationChart';
import QuestionnaireResultChart from '../chart/QuestionnaireResultChart';
import LatestQuestionnaireResultChart from '../chart/LatestQuestionnaireResultChart';
import AskQuestionnaire from './questionnaire/AskQuestionnaire';
import ErrorFallbackCard from '../utils/ErrorFallbackCard';
import { VITAL_CONFIG } from '../vitals/utils';

const initDefaultConfig = (features, vitalsConfig, questionnaires) => {
  const defaultConfig = [];

  // Add enabled vitals to the default configuration
  Object.values(vitalsConfig)
    .filter((v) => v.enabled)
    .forEach((vital) => {
      defaultConfig.push({
        id: vital.short_code,
        type: 'vital',
      });
    });

  // Add questionnaires with scores to the default configuration
  if (features.questionnaire) {
    questionnaires.forEach((questionnaire) => {
      if (questionnaire.has_score) {
        defaultConfig.push({
          id: questionnaire.id,
          type: 'questionnaire',
        });
      }
    });
  }

  return defaultConfig;
};

const Overview = ({ patientUuid }) => {
  const { t } = useTranslation();
  const { vitalsConfig, clinic, questionnaires } = useSelector((state) => state.clinic);

  // Memoize configurations
  const configurations = useMemo(() => {
    if (clinic.config.overview) {
      return clinic.config.overview.map((item) => {
        const config = { ...item };
        if (item.type === 'vital' && VITAL_CONFIG[item.id]) {
          config.config = VITAL_CONFIG[item.id].chartConfig;
        }
        return config;
      });
    }
    return initDefaultConfig(clinic.config.features_enable, vitalsConfig, questionnaires);
  }, [clinic.config.overview, clinic.config.features_enable, vitalsConfig, questionnaires]);

  // Render placeholder if configurations are empty
  if (!configurations || configurations.length === 0) {
    return (
      <Paper sx={{ p: 4, textAlign: 'center' }}>
        <InsertChartOutlinedIcon color="disabled" sx={{ fontSize: 60, mb: 2 }} />
        <Typography variant="h6" color="textSecondary">
          {t('No overview data available.')}
        </Typography>
      </Paper>
    );
  }

  // Function to render each configuration item
  const renderConfigurationItem = (config) => {
    switch (config.type) {
      case 'vital': {
        const vital = config.id;
        const measures = [];

        if (vitalsConfig[vital]) {
          const vitalConfig = vitalsConfig[vital];
          if (vitalConfig.config?.children) {
            vitalConfig.config.children.forEach((child) => {
              if (vitalsConfig[child]) {
                measures.push(vitalsConfig[child]);
              }
            });
          } else {
            measures.push(vitalConfig);
          }

          if (measures.length === 0) {
            // Invalid measures, show placeholder
            return (
              <Paper sx={{ p: 4, textAlign: 'center' }}>
                <WarningAmberIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
                <Typography variant="h6" color="textSecondary">
                  {t('Invalid vital configuration for "{{id}}".', { id: config.id })}
                </Typography>
              </Paper>
            );
          }

          return (
            <ErrorBoundary FallbackComponent={ErrorFallbackCard}>
              <ObservationChart
                patientUuid={patientUuid}
                measures={measures}
                config={vitalsConfig[vital]}
                autoHeight
              />
            </ErrorBoundary>
          );
        } else {
          // Vital not found, show placeholder
          return (
            <Paper sx={{ p: 4, textAlign: 'center' }}>
              <WarningAmberIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
              <Typography variant="h6" color="textSecondary">
                {t('Vital "{{id}}" not found.', { id: config.id })}
              </Typography>
            </Paper>
          );
        }
      }

      case 'questionnaire': {
        const questionnaireExists = questionnaires.some((q) => q.id === config.id);
        if (questionnaireExists) {
          return (
            <ErrorBoundary FallbackComponent={ErrorFallbackCard}>
              <QuestionnaireResultChart
                patientUuid={patientUuid}
                questionnaireId={config.id}
                autoHeight
              />
            </ErrorBoundary>
          );
        } else {
          // Questionnaire not found, show placeholder
          return (
            <Paper sx={{ p: 4, textAlign: 'center' }}>
              <WarningAmberIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
              <Typography variant="h6" color="textSecondary">
                {t('Questionnaire "{{id}}" not found.', { id: config.id })}
              </Typography>
            </Paper>
          );
        }
      }

      case 'latest_questionnaire': {
        const questionnaireExists = questionnaires.some((q) => q.id === config.id);
        if (questionnaireExists) {
          return (
            <ErrorBoundary FallbackComponent={ErrorFallbackCard}>
              <LatestQuestionnaireResultChart
                patientUuid={patientUuid}
                questionnaireId={config.id}
                autoHeight
              />
            </ErrorBoundary>
          );
        } else {
          // Questionnaire not found, show placeholder
          return (
            <Paper sx={{ p: 4, textAlign: 'center' }}>
              <WarningAmberIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
              <Typography variant="h6" color="textSecondary">
                {t('Latest questionnaire "{{id}}" not found.', { id: config.id })}
              </Typography>
            </Paper>
          );
        }
      }

      case 'ask_questionnaire': {
        const questionnaireExists = questionnaires.some((q) => q.id === config.id);
        if (questionnaireExists) {
          return (
            <Paper sx={{ width: '100%', p: 0 }}>
              <AskQuestionnaire patientUuid={patientUuid} questionnaireId={config.id} />
            </Paper>
          );
        } else {
          // Questionnaire not found, show placeholder
          return (
            <Paper sx={{ p: 4, textAlign: 'center' }}>
              <WarningAmberIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
              <Typography variant="h6" color="textSecondary">
                {t('Questionnaire "{{id}}" not found.', { id: config.id })}
              </Typography>
            </Paper>
          );
        }
      }

      default:
        // Unknown type, show placeholder
        return (
          <Paper sx={{ p: 4, textAlign: 'center' }}>
            <WarningAmberIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h6" color="textSecondary">
              {t('Unknown configuration type "{{type}}".', { type: config.type })}
            </Typography>
          </Paper>
        );
    }
  };

  return (
    <Grid container spacing={2} sx={{ mt: 1, mb: 3 }}>
      {configurations.map((config) => (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={config.type === 'ask_questionnaire' ? 12 : 6}
          xl={config.type === 'ask_questionnaire' ? 12 : 4}
          key={`${config.type}-${config.id}`}
        >
          {renderConfigurationItem(config)}
        </Grid>
      ))}
    </Grid>
  );
};

export default Overview;
