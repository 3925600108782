import React from 'react';
import tenantMetadataConfig from 'metadataConfig';

const AppBarLogo = () => {
  return (
    <>
      {tenantMetadataConfig.logo && <img src={tenantMetadataConfig.logo} alt="Logo" height="30px" />}
      {tenantMetadataConfig.JSXLogo && <tenantMetadataConfig.JSXLogo />}
    </>
  );
};

export default AppBarLogo;
