import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';

const NavigationList = ({ navigationItems, open, handleNavigation }) => {
  return (
    <List>
      {navigationItems.map((item) => {
        const selected = window.location.pathname.includes(item.id);

        return (
          <Tooltip title={!open ? item.name : ''} placement="right" arrow key={item.id}>
            <ListItem
              disablePadding
              sx={{
                mb: 1,
                mx: 1,
                width: 'auto',
              }}
            >
              <ListItemButton
                id={item.id}
                onClick={() => handleNavigation(item.path)}
                selected={selected}
                sx={{
                  minHeight: 48,
                  height: 48,
                  width: '100%',
                  px: selected ? 0 : 2,
                  justifyContent: open ? 'initial' : 'center',
                  borderRadius: 1,
                  '&.Mui-selected': {
                    backgroundColor: (theme) => theme.palette.primary.light,
                  },
                }}
              >
                {selected && (
                  <Box
                    sx={{
                      width: 3,
                      minWidth: 3,
                      height: '80%',
                      backgroundColor: (theme) => theme.palette.primary.main,
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4,
                    }}
                  />
                )}
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    ml: selected ? 1.2 : 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: selected ? 'primary.main' : 'text.secondary',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={item.name}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: selected ? 'primary.main' : 'text.secondary',
                    }}
                  />
                )}
              </ListItemButton>
            </ListItem>
          </Tooltip>
        );
      })}
    </List>
  );
};

export default NavigationList;
