import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import InvitationCreation from '../components/invitations/InvitationsCreation';
import { INVITATION_TYPE } from '../components/invitations/utils';
import TablePaginationSkeleton from '../components/skeletons/TablePaginationSkeleton';
import { greyboxApiActions } from '../redux/api';

const PendingInvitations = () => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { invitation } = greyboxApiActions;
  const [updateInvitation] = invitation.update();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // State variables for selection and pagination
  const [selected, setSelected] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Dialogs and snackbar
  const [openCreationDialog, setOpenCreationDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);  
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const { data = { results: [], count: 0 }, isFetching } = invitation.list({
    status: 'pending',
    page: page,
    page_size: rowsPerPage,
    clinic: clinic.id
  });

  useEffect(() => {
    setDisabled(selected.length === 0 || isFetching);
  }, [selected, isFetching]);

  const columns = [
    {
      id: 'firstName',
      label: t('First name'),
      minWidth: 100,
      value: (row) => row.invitationFormFields?.firstName || '',
    },
    {
      id: 'lastName',
      label: t('Last name'),
      minWidth: 100,
      value: (row) => row.invitationFormFields?.lastName || '',
    },
    {
      id: 'type',
      label: t('Type'),
      minWidth: 60,
      value: (row) => t(INVITATION_TYPE[row.type]),
    },
    {
      id: 'email',
      label: t('Email'),
      minWidth: 170,
    },
    {
      id: 'phoneNumber',
      label: t('Phone number'),
      minWidth: 100,
    },
    {
      id: 'modifyDate',
      label: t('Invitation date'),
      minWidth: 100,
      format: (value) => moment(value).format('YYYY-MM-DD'),
    },
    {
      id: 'invitedBy',
      label: t('Invited by'),
      minWidth: 100,
      value: (row) => row.hcpName,
    },
  ];

  const responsiveColumns = isSmallScreen
    ? columns.filter((col) => ['firstName', 'lastName', 'email'].includes(col.id))
    : columns;

  const handleSelectAll = () => {
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected(data.results.map((row) => row.uuid));
    }
  };

  const handleSelectionToggle = (e, id) => {
    e.stopPropagation();
    setSelected((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleOpenDeleteDialog = (ids) => {
    const invitationsToDelete = ids || selected;
    if (invitationsToDelete.length > 0) {
      setSelected(invitationsToDelete);
      setOpenDeleteDialog(true);
    }
  };

  const handleDeleteInvitations = async () => {
    setIsDeleting(true);
    try {
      const deletePromises = selected.map((id) =>
        updateInvitation({
          id: id,
          body: {
            modifyDate: moment().toISOString(true),
            status: 'cancelled',
          },
        })
      );

      await Promise.all(deletePromises);
      setSelected([]);
      setOpenDeleteDialog(false);
      setSnackbarMessage(t('Invitation(s) deleted successfully'));
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error deleting invitations:', error);
      // Consider showing an error snackbar here if desired
      setOpenDeleteDialog(false);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleResendInvitations = (ids) => {
    const invitationsToResend = ids || selected;
    if (invitationsToResend.length > 0) {
      Promise.all(
        invitationsToResend.map((id) =>
          updateInvitation({
            id: id,
            modifyDate: moment().toISOString(true),
          })
        )
      ).then(() => {
        setSelected([]);
        setSnackbarMessage(t('Invitation(s) resent successfully'));
        setOpenSnackbar(true);
      });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason !== 'clickaway') {
      setOpenSnackbar(false);
    }
  };

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant={isSmallScreen ? 'h6' : 'h5'}>
            {t('Pending Invitations')}
          </Typography>
          {!isSmallScreen && (
            <Typography variant="subtitle1">
              {t('A reminder is automatically sent 3 days after registration if not activated')}
            </Typography>
          )}
        </Box>
        <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
          <Grid
            container
            alignItems="center"
            sx={{
              padding: theme.spacing(1),
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Grid item xs={12} sm="auto">
              <Button
                onClick={() => setOpenCreationDialog(true)}
                sx={{ mr: 1, mb: isSmallScreen ? 1 : 0 }}
                variant="contained"
                fullWidth={isSmallScreen}
                disabled={isFetching}
              >
                {t('New Invitation')}
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button
                onClick={() => handleResendInvitations()}
                sx={{ mr: 1, mb: isSmallScreen ? 1 : 0 }}
                startIcon={<SendIcon />}
                disabled={disabled}
                fullWidth={isSmallScreen}
              >
                {t('Resend')}
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button
                onClick={() => handleOpenDeleteDialog()}
                startIcon={<DeleteIcon />}
                sx={{ mr: 1, mb: isSmallScreen ? 1 : 0 }}
                disabled={disabled}
                color="error"
                fullWidth={isSmallScreen}
              >
                {t('Delete')}
              </Button>
            </Grid>
            <Grid item xs>
              {isFetching ? (
                <Box sx={{ ml: 'auto' }}>
                  <TablePaginationSkeleton />
                </Box>
              ) : (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  sx={{ ml: 'auto' }}
                  count={data.count}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={t('Rows per page')}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${t('of')} ${count}`
                  }
                  page={page - 1}
                  onPageChange={(event, newPage) => setPage(newPage + 1)}
                  onRowsPerPageChange={(event) =>
                    setRowsPerPage(parseInt(event.target.value, 10))
                  }
                />
              )}
            </Grid>
          </Grid>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < data.results.length}
                    checked={data.results.length > 0 && selected.length === data.results.length}
                    onChange={handleSelectAll}
                    disabled={isFetching}
                  />
                </TableCell>
                {responsiveColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                {isSmallScreen && <TableCell align="right">{t('Actions')}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                Array.from(new Array(rowsPerPage)).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell padding="checkbox">
                      <Skeleton variant="rectangular" width={24} height={24} />
                    </TableCell>
                    {responsiveColumns.map((column) => (
                      <TableCell key={column.id}>
                        <Skeleton variant="text" />
                      </TableCell>
                    ))}
                    {isSmallScreen && (
                      <TableCell align="right">
                        <Skeleton variant="rectangular" width={48} height={24} />
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : data.results.length > 0 ? (
                data.results.map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.uuid}
                    selected={selected.includes(row.uuid)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selected.includes(row.uuid)}
                        onClick={(event) => handleSelectionToggle(event, row.uuid)}
                      />
                    </TableCell>
                    {responsiveColumns.map((column) => {
                      const value = column.value ? column.value(row) : row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    {isSmallScreen && (
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          onClick={() => handleResendInvitations([row.uuid])}
                        >
                          <SendIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleOpenDeleteDialog([row.uuid])}
                          color="error"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={responsiveColumns.length + 2}
                    align="center"
                    sx={{ height: '300px' }}
                  >
                    {t('No data')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">
          {t('Confirm Deletion')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure you want to delete the selected invitation(s)? This action cannot be undone.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>
            {t('Cancel')}
          </Button>
          <LoadingButton
            onClick={handleDeleteInvitations}
            color="error"
            loading={isDeleting}
            loadingPosition="start"
            startIcon={<DeleteIcon />}
          >
            {t('Delete')}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Snackbar Notification */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Invitation Creation Dialog */}
      <InvitationCreation open={openCreationDialog} handleClose={() => setOpenCreationDialog(false)} />
    </>
  );
};

export default PendingInvitations;
