import React, { useEffect, useState, useRef } from 'react';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Menu,
  Snackbar,
  SpeedDial,
  SpeedDialAction,
  Stack,
  useMediaQuery,
  useTheme,
  Skeleton,
} from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MenuIcon from '@mui/icons-material/Menu';
import QuizIcon from '@mui/icons-material/Quiz';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AlertDetails from '../components/alert/AlertDetails';
import CareTeam from '../components/care-team/CareTeam';
import CareTeamDetails from '../components/care-team/CareTeamDetails';
import {
  Calendar,
  ClinicalDataDetails,
  ClinicalDataTable,
  ClinicalNotePanel,
  DateRangePicker,
  Documents,
  LabResultDetails,
  LabResultsWrapper,
  MedicalActs,
  MedicalActSummary,
  MedicationTab,
  Overview,
  PatientActionPanel,
  PatientInformations,
  PatientProfileNavigation,
  QuestionnaireDetails,
  QuestionnaireSelector,
  Reports,
} from '../components/patient-profile';
import PatientQuestionnairesCalendar from '../components/patient-profile/calendar/PatientQuestionnairesCalendar';
import ClinicalNotePage from '../components/patient-profile/clinicalNote/ClinicalNotePage';
import PrescriptionFormPage from '../components/patient-profile/medication/prescription/PrescriptionFormPage';
import QuestionnairesHistory from '../components/patient-profile/questionnaire/QuestionnairesHistory';
import AddVitalsDialog from '../components/vitals/AddVitalsDialog';
import VitalDetails from '../components/vitals/VitalDetails';
import { greyboxApiActions } from '../redux/api';
import { setIsLoading } from '../redux/pageLoader';
import { initializePatient } from '../redux/patient';
import { initializeDateRange } from '../redux/patientProfileDateRange';
import { getPermissions } from '../redux/permissions';

const StaticMenuSkeleton = () => (
  <Stack sx={{ minWidth: '275px', mr: 1, maxWidth: '300px' }} spacing={1}>
    <Skeleton variant="rectangular" height={150} />
    <Skeleton variant="rectangular" height={100} />
    <Skeleton variant="rectangular" height={400} />
  </Stack>
);

const ContentSkeleton = () => (
  <Box sx={{ flex: 1 }}>
    <Skeleton variant="rectangular" height={50} />
    <Skeleton variant="rectangular" height={400} sx={{ mt: 2 }} />
  </Box>
);

const StaticMenu = ({ patientUuid }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { clinic } = useSelector((state) => state.clinic);
  const { access } = useSelector((state) => state.user);

  if (isMobile) return null;

  return (
    <Stack sx={{ minWidth: '275px', mr: 1, maxWidth: '300px' }} spacing={1}>
      <PatientActionPanel />
      {clinic.config.features_enable.medical_acts && access === 'PT' && (
        <MedicalActSummary patientUuid={patientUuid} />
      )}
      <PatientProfileNavigation />
    </Stack>
  );
};

const PatientProfile = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { uuid } = useParams();
  const { t } = useTranslation();

  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [addVitalsOpen, setAddVitalsOpen] = useState(false);
  const [questionnaireOpen, setQuestionnaireOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  const [consultationRecorded, setConsultationRecorded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { clinic } = useSelector((state) => state.clinic);
  const { access, accountId } = useSelector((state) => state.user);
  const { edit } = useSelector((state) => state.permissions);
  const dateRange = useSelector((state) => state.patientProfileDateRange);
  const patient = useSelector((state) => state.patient);

  const { medicalAct } = greyboxApiActions;
  const [addMedicalAct] = medicalAct.add();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Timer reference
  const registrationTimerRef = useRef(null);

  useEffect(() => {
    dispatch(initializePatient(uuid));
    dispatch(getPermissions(accountId, uuid));
  }, [uuid, accountId, dispatch]);

  useEffect(() => {
    if (patient.createdDate) {
      dispatch(
        initializeDateRange(
          clinic.id,
          clinic.config.features_enable.date_range,
          patient.createdDate
        )
      );
    }
  }, [patient.createdDate, clinic.id, clinic.config.features_enable.date_range, dispatch]);

  useEffect(() => {
    if (
      clinic &&
      access &&
      clinic.config.features_enable.automatic_medical_act &&
      access === 'PT' &&
      !consultationRecorded
    ) {
      // Delay in milliseconds (3 seconds)
      const delay = 3000;

      // Start the timer to delay the consultation recording
      registrationTimerRef.current = setTimeout(() => {
        handleMedicalActRegistration();
      }, delay);

      // Cleanup function to clear the timer if the component unmounts before the delay
      return () => {
        if (registrationTimerRef.current) {
          clearTimeout(registrationTimerRef.current);
          registrationTimerRef.current = null;
        }
      };
    }
  }, [clinic, access, consultationRecorded]);

  useEffect(() => {
    const isLoading = patient.isLoading || dateRange.range === null;
    dispatch(setIsLoading(isLoading));
  }, [patient.isLoading, dateRange.range, dispatch]);

  const handleQuestionnaireClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setQuestionnaireOpen(false);
    }
  };

  const handleMedicalActRegistration = () => {
    addMedicalAct({
      body: {
        patient: uuid,
        hcp: accountId,
        clinic: clinic.id,
      },
    })
      .then(() => {
        setConsultationRecorded(true);
      })
      .catch((error) => {
        console.error('Error recording consultation:', error);
        // Handle error if needed
      });
  };

  const speedDialActions = [
    {
      key: 'addVitals',
      icon: <LocalHospitalIcon />,
      name: t('Add vitals'),
      onClick: () => setAddVitalsOpen(true),
      access: 'ALL',
    },
    {
      key: 'fillQuestionnaire',
      icon: <QuizIcon />,
      name: t('Fill a questionnaire'),
      onClick: () => setQuestionnaireOpen(true),
      access: 'ALL',
    },
  ];

  const filteredActions = speedDialActions.filter(
    (action) => action.access === 'ALL' || action.access.includes(access)
  );

  const availableFeatures = new Set(filteredActions.map((action) => action.key));

  if (patient.isLoading || dateRange.range === null) {
    return (
      <Box display="flex">
        {!isMobile && <StaticMenuSkeleton />}
        <ContentSkeleton />
      </Box>
    );
  }

  if (patient.isError) {
    return <Navigate to="/" />;
  }

  return (
    <Box>
      {isMobile && (
        <Box width="100%" display="flex">
          <IconButton
            color="primary"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            sx={{ ml: 'auto', mr: 1, my: 1 }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
          >
            <PatientProfileNavigation />
          </Menu>
        </Box>
      )}
      {clinic.config.features_enable.medical_acts && !clinic.config.features_enable.automatic_medical_act && access === 'PT' && (
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setSnackbarOpen(false)}
          >
          {consultationRecorded ? (
            <Alert onClose={() => setSnackbarOpen(false)} severity="success">
              {t('Consultation recorded')}
            </Alert>
          ) : (
            <Alert
              action={
                <>
                  <Button onClick={() => setSnackbarOpen(false)}>
                    {t('No')}
                  </Button>
                  <Button onClick={handleMedicalActRegistration}>
                    {t('Yes')}
                  </Button>
                </>
              }
              onClose={() => setSnackbarOpen(false)}
              severity="info"
            >
              {`${t('Do you want to record a patient file consultation')}?`}
            </Alert>
          )}
          </Snackbar>
        )}
      <Box display="flex">
        <StaticMenu patientUuid={uuid} />
        <Routes>
          <Route path="vitals/:vital" element={<VitalDetails patientUuid={uuid} />} />
          <Route
            path="questionnaire/:questionnaireId"
            element={<QuestionnaireDetails patientUuid={uuid} />}
          />
          <Route
            path="questionnaires-history"
            element={<QuestionnairesHistory patientUuid={uuid} />}
          />
          <Route path="lab-results" element={<LabResultsWrapper uuid={uuid} />} />
          <Route
            path="lab-results/:labResultCode"
            element={<LabResultDetails uuid={uuid} />}
          />
          <Route path="clinical-data" element={<ClinicalDataTable uuid={uuid} />} />
          <Route
            path="clinical-data/:clinicalDataCode"
            element={<ClinicalDataDetails />}
          />
          <Route
            path="care-team/:id"
            element={<CareTeamDetails patientUuid={uuid} />}
          />
          <Route path="care-team" element={<CareTeam hasPatient={true} />} />
          <Route path="medications" element={<MedicationTab uuid={uuid} />} />
          <Route path="prescriptions/new" element={<PrescriptionFormPage />} />
          <Route
            path="prescriptions/:prescriptionId"
            element={<PrescriptionFormPage />}
          />
          <Route path="reports" element={<Reports patientUuid={uuid} />} />
          <Route path="calendar" element={<Calendar patientUuid={uuid} />} />
          <Route
            path="questionnaires-calendar"
            element={<PatientQuestionnairesCalendar />}
          />
          <Route
            path="clinical-note"
            element={<ClinicalNotePanel uuid={uuid} />}
          />
          <Route
            path="clinical-note/:clinicalNoteId"
            element={<ClinicalNotePage />}
          />
          <Route
            path="overview"
            element={
              <Box sx={{ width: '100%' }}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <DateRangePicker />
                </Box>
                <Overview patientUuid={uuid} />
              </Box>
            }
          />
          <Route
            path="alert"
            element={<AlertDetails type="alert" patientUuid={uuid} />}
          />
          <Route
            path="reminder"
            element={<AlertDetails type="reminder" patientUuid={uuid} />}
          />
          <Route path="information" element={<PatientInformations />} />
          <Route
            path="medical-acts"
            element={<MedicalActs patientUuid={uuid} />}
          />
          <Route path="documents" element={<Documents />} />
          <Route index element={<Navigate to="overview" />} />
          <Route path="*" element={<Navigate to="overview" />} />
        </Routes>
      </Box>
      {edit &&
        clinic.config.features_enable.speed_dial &&
        filteredActions.length > 0 && (
          <SpeedDial
            ariaLabel="SpeedDial"
            sx={{ position: 'fixed', bottom: '8%', right: '5%' }}
            icon={<SpeedDialIcon />}
            onClose={() => setSpeedDialOpen(false)}
            onOpen={() => setSpeedDialOpen(true)}
            open={speedDialOpen}
          >
            {filteredActions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.onClick}
              />
            ))}
          </SpeedDial>
        )}
      {addVitalsOpen && availableFeatures.has('addVitals') && (
        <AddVitalsDialog
          open={addVitalsOpen}
          handleClose={() => setAddVitalsOpen(false)}
          patientUuid={uuid}
        />
      )}
      {questionnaireOpen && availableFeatures.has('fillQuestionnaire') && (
        <QuestionnaireSelector
          patientUuid={uuid}
          open={questionnaireOpen}
          handleClose={handleQuestionnaireClose}
        />
      )}
    </Box>
  );
};

export default PatientProfile;
