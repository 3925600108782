import React, { useEffect } from 'react';
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormTextField, DatePicker, Duration, QuestionnaireSelect, Recurrence } from '../../form-inputs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Row from '../../Row';

const EventForm = ({ formik, showRecurrence, setShowRecurrence }) => {
    const { t } = useTranslation();

    // When toggling all_day, adjust start/end times
    useEffect(() => {
        if (formik.values.all_day) {
            const startOfDay = moment(formik.values.start_datetime).startOf('day');
            // Set end to the next day (exclusive end)
            const endOfDay = moment(startOfDay).endOf('day');
            formik.setFieldValue('start_datetime', startOfDay);
            formik.setFieldValue('end_datetime', endOfDay);
        } else {
            // If turning off all_day and if times are currently at start of day,
            // we can default to a 30-minute event starting now if it makes sense.
            // Adjust only if end time <= start time.
            let start = moment(formik.values.start_datetime);
            let end = moment(formik.values.end_datetime);
            if (!end.isAfter(start)) {
                end = moment(start).add(30, 'minutes');
            }
            formik.setFieldValue('start_datetime', start);
            formik.setFieldValue('end_datetime', end);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.all_day]);

    const handleChangeDate = (date) => {
        if (date) {
            if (formik.values.all_day) {
                // All-day: start at this date's midnight, end next day midnight
                const startOfDay = moment(date).startOf('day');
                const endOfDay = moment(startOfDay).endOf('day');
                formik.setFieldValue('start_datetime', startOfDay);
                formik.setFieldValue('end_datetime', endOfDay);
            } else {
                // Timed event: keep hour/minute from current start time
                const currentStart = moment(formik.values.start_datetime);
                const newDate = moment(date)
                    .hour(currentStart.hour())
                    .minute(currentStart.minute());
                formik.setFieldValue('start_datetime', newDate);
                // Adjust end time if it's before start time or no difference
                let end = moment(formik.values.end_datetime);
                if (!end.isAfter(newDate)) {
                    end = moment(newDate).add(30, 'minutes');
                }
                formik.setFieldValue('end_datetime', end);
            }
        }
    };

    const handleChangeTime = (time) => {
        if (time && !formik.values.all_day) {
            // For timed events, update start time and maintain a 30-minute duration if needed
            const newDate = moment(formik.values.start_datetime)
                .hour(moment(time).hour())
                .minute(moment(time).minute());
            formik.setFieldValue('start_datetime', newDate);

            let end = moment(formik.values.end_datetime);
            if (!end.isAfter(newDate)) {
                end = moment(newDate).add(30, 'minutes');
            }
            formik.setFieldValue('end_datetime', end);
        }
    };

    return (
        <Box flexGrow={1} p={2}>
            <Row sx={{ mb: 2 }}>
                <FormTextField
                    name="name"
                    formik={formik}
                    label={t('Name')}
                    required
                    sx={{ flex: 1, mr: 2 }}
                />
                <FormTextField
                    name="description"
                    formik={formik}
                    label={t('Description')}
                    sx={{ flex: 1, ml: 2 }}
                />
            </Row>
            <Duration formik={formik} name="reminder" label={t('Reminder')} sx={{ mb: 2 }} />
            <Box sx={{ mb: 2 }}>
                {formik.values.resourcetype === 'OnlineMeetingEvent' ? (
                    <FormTextField
                        name="join_url"
                        required
                        type="url"
                        formik={formik}
                        label={t('Online meeting link')}
                        sx={{ width: '100%' }}
                    />
                ) : (
                    <FormTextField
                        name="location"
                        formik={formik}
                        label={t('Location')}
                        sx={{ width: '100%' }}
                    />
                )}
            </Box>
            {formik.values.resourcetype === 'QuestionnaireEvent' && (
                <>
                    <QuestionnaireSelect
                        name="questionnaire"
                        formik={formik}
                        required
                        sx={{mb: 2, width: '100%' }}
                        questionnaireId={formik.values.questionnaire}
                    />
                    <Duration formik={formik} name="response_reminder" label={t('Response reminder')} sx={{ mb: 2 }} />
                </>
            )}

            {formik.values.all_day ? (
                <Row sx={{ mb: 2 }}>
                    <DatePicker
                        formik={formik}
                        label={t('Start')}
                        name="start_datetime"
                        onChange={(d) => handleChangeDate(d)}
                        sx={{ flex: 1, mr: 2 }}
                    />
                    {/* For all-day events, we can allow selecting the end date if needed,
                        but typically all-day events are defined by start date and end date
                        (with end date exclusive). The code below allows choosing an end date.
                        If you want a single-day event, you can hide the end date field. */}
                    <DatePicker
                        formik={formik}
                        name="end_datetime"
                        label={t('End')}
                        onChange={(d) => {
                            if (d) {
                                // Set end date to chosen date's start (next day not needed if user selects explicitly)
                                const endOfDay = moment(d).startOf('day');
                                // Ensure end is after start
                                let start = moment(formik.values.start_datetime).startOf('day');
                                if (!endOfDay.isAfter(start)) {
                                    // If chosen end is before or same as start, add one day
                                    formik.setFieldValue('end_datetime', moment(start).endOf('day'));
                                } else {
                                    formik.setFieldValue('end_datetime', endOfDay);
                                }
                            }
                        }}
                        sx={{ flex: 1, ml: 2 }}
                    />
                </Row>
            ) : (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Row sx={{ mb: 2 }}>
                        <Box sx={{ flex: 1, mr: 2 }}>
                            <MuiDatePicker
                                label={t('Date')}
                                sx={{ width: '100%' }}
                                value={formik.values.start_datetime}
                                onChange={(d) => handleChangeDate(d)}
                            />
                        </Box>
                        <Box sx={{ flex: 1, mr: 2 }}>
                            <TimePicker
                                label={t('Start Time')}
                                sx={{ width: '100%' }}
                                value={formik.values.start_datetime}
                                onChange={(time) => handleChangeTime(time)}
                                ampm={false}
                            />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <TimePicker
                                label={t('End Time')}
                                sx={{ width: '100%' }}
                                value={formik.values.end_datetime}
                                minTime={formik.values.start_datetime}
                                onChange={(time) => formik.setFieldValue('end_datetime', time)}
                                ampm={false}
                            />
                        </Box>
                    </Row>
                </LocalizationProvider>
            )}
            <Row sx={{ mb: 2 }}>
                <FormControlLabel
                    sx={{ ml: 1 }}
                    control={(
                        <Checkbox
                            checked={formik.values.all_day}
                            onChange={() => formik.setFieldValue('all_day', !formik.values.all_day)}
                        />
                    )}
                    label={t('All Day')}
                />
            </Row>
            <Button
                sx={{ mb: 2 }}
                onClick={() => {
                    if (showRecurrence) {
                        formik.setFieldValue('recurrences', '');
                    }
                    setShowRecurrence(!showRecurrence);
                }}
                variant="outlined"
            >
                {showRecurrence ? t('Remove recurrence') : t('Add recurrence')}
            </Button>

            {(showRecurrence || formik.values.recurrences !== '') && (
                <Box border={(theme) => `1px solid ${theme.palette.grey[300]}`} borderRadius={2} p={1} sx={{ mb: 2 }}>
                    <Recurrence
                        onChange={(recurrence) => formik.setFieldValue('recurrences', recurrence)}
                        rule={formik.values.recurrences}
                    />
                </Box>
            )}
        </Box>
    );
};

export default EventForm;
