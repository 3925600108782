import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material'; // Import Typography for error messages
import { Formik } from 'formik';
import * as yup from 'yup'; // Import Yup for validation
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';
import { setTempAuthorization } from '../../redux/authorization';
import { setIsInvitation } from '../../redux/invitation';

const TokenValidation = (props) => {
  const { email } = props;
  const { t } = useTranslation();
  const { tokenValidation } = greyboxApiActions;
  const dispatch = useDispatch();
  const [sendToken, result] = tokenValidation.add();

  // Define the Yup validation schema
  const validationSchema = yup.object().shape({
    token: yup.string().required(t('Token is required')),
  });

  const initialValues = { token: '' };

  const onSubmit = (value, formikBag) => {
    sendToken({
      body: {
        email: email,
        token: value.token,
      },
    })
      .unwrap()
      .then(() => {
        formikBag.setSubmitting(false);
      })
      .catch((error) => {
        if (error.status === 400) {
          formikBag.setErrors(error.data);

          if (error.data.non_field_errors) {
            formikBag.setStatus({ generalError: error.data.non_field_errors });
          }

        } else {
          formikBag.setStatus({ generalError: t('An unexpected error occurred.') });
        }
      })
      .finally(() => {
        formikBag.setSubmitting(false);
      });
  };

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(setIsInvitation(true));
      dispatch(setTempAuthorization({ token: `Token ${result.data.token}` }));
    }
  }, [result]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema} // Use the Yup schema here
      onSubmit={onSubmit}
    >
      {(formikBag) => (
        <form onSubmit={formikBag.handleSubmit}>
          <Box display="flex" alignItems="center" flexDirection="column" margin="none">
            <TextField
              name="token"
              placeholder={t('Enter validation code')}
              value={formikBag.values.token}
              onChange={formikBag.handleChange}
              onBlur={formikBag.handleBlur} // Add handleBlur
              fullWidth
              required
              error={Boolean(formikBag.touched.token && formikBag.errors.token)}
              helperText={formikBag.touched.token && formikBag.errors.token}
            />
          </Box>
          {formikBag.status && formikBag.status.generalError && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {formikBag.status.generalError}
            </Typography>
          )}
          <Box display="flex" alignItems="center" flexDirection="column" sx={{ mt: 1 }}>
            <LoadingButton
              size="large"
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              loading={formikBag.isSubmitting}
            >
              {t('Send')}
            </LoadingButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default TokenValidation;
