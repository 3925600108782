import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { MdCheckCircle, MdExitToApp, MdDeleteForever } from 'react-icons/md';

const getConfirmationOptions = (t) => [
  {
    title: t('Finalize Note'),
    message: t(
      'Are you sure you want to finalize this note? Once finalized, it cannot be edited.'
    ),
    confirmText: t('Finalize'),
    cancelText: t('Cancel'),
    actionType: 'finalize',
    buttonColor: 'primary',
    icon: <MdCheckCircle size={24} />,
  },
  {
    title: t('Unsaved Changes'),
    message: t('You have unsaved changes. Are you sure you want to leave?'),
    confirmText: t('Leave'),
    cancelText: t('Stay'),
    actionType: 'leave',
    buttonColor: 'error',
    icon: <MdExitToApp size={24} />,
  },
  {
    title: t('Delete Note'),
    message: t(
      'Are you sure you want to delete this note? This action cannot be undone.'
    ),
    confirmText: t('Delete'),
    cancelText: t('Cancel'),
    actionType: 'delete',
    buttonColor: 'error',
    icon: <MdDeleteForever size={24} />,
  },
];

const ConfirmationModal = ({
  isOpen,
  dialogActionType,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const confirmationOptions = getConfirmationOptions(t);
  const currentOption = confirmationOptions.find(
    (option) => option.actionType === dialogActionType
  );

  if (!currentOption) return null;

  const handleConfirm = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
    onClose();
  };

  const handleCancel = () => {
    setLoading(false);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      disableEnforceFocus
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {currentOption.icon}
          <span>{currentOption.title}</span>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>{currentOption.message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={loading}>
          {currentOption.cancelText}
        </Button>
        <LoadingButton
          variant="contained"
          color={currentOption.buttonColor}
          onClick={handleConfirm}
          loading={loading}
        >
          {currentOption.confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
