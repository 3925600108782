import { Box, Paper, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BasicInfoTab from './informations/BasicInfoTab';
import ContactTab from './informations/ContactTab';
import ThresholdTab from './informations/ThresholdTab';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const { access } = useSelector((state) => state.user);
  const { clinic } = useSelector((state) => state.clinic);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isThresholdFeatureEnabled = clinic?.config.features_enable.threshold;

  return (
    <Paper sx={{ width: '100%', height: 'fit-content' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t('Informations')} />
          <Tab label={t('Contacts')} />
          {access === 'PT' && isThresholdFeatureEnabled && (
            <Tab label={t('Thresholds')} />
          )}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <BasicInfoTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ContactTab />
      </CustomTabPanel>
      {access === 'PT' && isThresholdFeatureEnabled && (
        <CustomTabPanel value={value} index={2}>
          <ThresholdTab />
        </CustomTabPanel>
      )}
    </Paper>
  );
}
