import {
  Tooltip, Box, Typography, Chip,
  Badge,
  IconButton,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VitalCell from './VitalCell';
import QuestionnaireSummaryButton from '../patient-profile/questionnaire/QuestionnaireSummaryButton';
import AlertModal from '../alert/AlertModal';
import ReminderModal from '../patient-profile/components/ReminderModal';
import { greyboxApiActions } from '../../redux/api';
import { DialogChatBox } from '../chat';
import { BiMessageCheck, BiMessageError } from "react-icons/bi";

const ColoredCircle = (props) => {
  const {
    onClick,
    borderColor,
    children,
  } = props;

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={onClick}
    >
      <Box sx={{
        height: '30px',
        width: '30px',
        borderRadius: '50%',
        border: 'solid 3.5px',
        borderColor: (theme) => theme.palette[borderColor].main,
        '&:hover': onClick && {
          backgroundColor: (theme) => theme.palette[borderColor].main,
          color: (theme) => theme.palette[borderColor].contrastText,
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: onClick ? 'pointer' : '',
      }}
      >
        {children}
      </Box>
    </Box>
  );
};

const checkIfOutsideThresholds = (value, min, max) => ({
  bellow: value < min,
  above: value > max,
});

const MedicalActCell = ({ date, id }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { t } = useTranslation();
  const { medicalAct } = greyboxApiActions;
  const { data = {}, isFetching } = medicalAct.list({ id: id, expand: 'hcp' }, { skip: !tooltipOpen });

  return (
    date ? (
      <Tooltip
        open={tooltipOpen}
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
        title={isFetching ? `${t('Loading')}...` : (
          `${data.medical_act_type_name} ${t('by')} ${data.hcp?.first_name} ${data.hcp?.last_name}`
        )}
        arrow
      >
        <span>
          {moment(date).format('ll')}
        </span>
      </Tooltip>
    ) : (
      <span>---</span>
    )
  );
};

const alertCellContent = (active_alert_count, latest_alert_timestamp) => {
  if (active_alert_count > 0) {
    return (
      <Typography>
        {active_alert_count}
      </Typography>
    );
  }

  if (latest_alert_timestamp) {
    return (
      <CheckIcon color="success" />
    );
  }

  return null;
};

/**
 * Based on dashboard config, this component will select
 * corresponding data and apply styling for each cells of
 * a row in the dashboard.
 */

const CellContentSelector = (props) => {
  const { data, type, handleRefresh } = props;
  const [reminderModalOpen, setReminderModalOpen] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [threadModalOpen, setThreadModalOpen] = useState(false);
  const { clinic } = useSelector((state) => state.clinic);
  let customTooltip = '';
  const currentConfig = clinic.config.dashboard.length > 0
    ? clinic.config.dashboard.find((c) => c.code === type) : null;

  let outsideThresholds = false;
  let display = '---';
  let latestDate = null;
  const { t, i18n } = useTranslation();

  const alertCellCaption = () => {
    if (data.active_alert_count > 0) {
      return t('activeAlert', { count: data.active_alert_count });
    }

    if (data.latest_alert_timestamp) {
      return t('All follow-up registered');
    }
    return null;
  };

  switch (type) {
    case 'alert':
      const { active_alert_count, latest_alert_timestamp } = data;
      const hasAlert = active_alert_count > 0;

      if (active_alert_count > 0 || latest_alert_timestamp) {
        const handleClose = (followUpRecorded = false) => {
          setAlertModalOpen(false);
          if (followUpRecorded) {
            handleRefresh(true);
          }
        };

        return (
          <>
            <Tooltip title={alertCellCaption()} arrow enterDelay={500} enterNextDelay={500}>
              <div>
                <ColoredCircle
                  borderColor={hasAlert ? 'error' : latest_alert_timestamp && 'success'}
                  onClick={hasAlert ? (e) => {
                    e.stopPropagation();
                    setAlertModalOpen(true);
                  } : null}
                >
                  {alertCellContent(active_alert_count, latest_alert_timestamp)}
                </ColoredCircle>
              </div>
            </Tooltip>
            <AlertModal
              patient={{
                uuid: data.id,
                firstName: data.first_name,
                lastName: data.last_name,
              }}
              handleClose={handleClose}
              open={alertModalOpen}
              questionnaireInfo={data.latest_dq_answer}
            />
          </>
        );
      }
      return null;
    case 'label':
      if (data.label) {
        const labels = data.label.split(',');

        return (
          <Box mx={-3}>
            {labels.map((label, index) => (
              <Chip size="small" sx={{ m: 0.2, fontSize: '0.75rem' }} key={label} label={label} />
            ))}
          </Box>
        );
      }
      return '---';
    case 'bp':
      if (data.latest_blood_pressure) {
        const { diastolic, systolic, timestamp } = data.latest_blood_pressure;

        if (data.blood_pressure_threshold) {
          outsideThresholds = (
            checkIfOutsideThresholds(
              diastolic,
              data.blood_pressure_threshold.diastolic_min,
              data.blood_pressure_threshold.diastolic_max,
            )
            || checkIfOutsideThresholds(
              systolic,
              data.blood_pressure_threshold.systolic_min,
              data.blood_pressure_threshold.systolic_max,
            )
          );
        }

        latestDate = timestamp;
        display = `${systolic}/${diastolic}`;
      }

      return (
        <VitalCell
          timestamp={latestDate}
          outsideThresholds={outsideThresholds}
        >
          {display}
        </VitalCell>
      );
    case 'bg':
      if (data.latest_blood_glucose) {
        const { value, timestamp } = data.latest_blood_glucose;

        if (data.blood_glucose_threshold) {
          outsideThresholds = checkIfOutsideThresholds(
            value,
            data.blood_glucose_threshold.min,
            data.blood_glucose_threshold.max,
          );
        }

        latestDate = timestamp;
        display = value;
      }

      return (
        <VitalCell
          timestamp={latestDate}
          outsideThresholds={outsideThresholds}
        >
          {display}
        </VitalCell>
      );
    case 'birth_date':
      const age = moment().diff(moment(data.birth_date), 'days');
      return (
        <Tooltip title={t('Age')} arrow>
          <span>
            {age || '---'}
          </span>
        </Tooltip>
      );
    case 'createdDate':
      return (
        <span>
          {moment(data.created).format('ll')}
        </span>
      );
    case 'createdPeriod':
      return (
        <Tooltip title={moment(data.created).format('ll')} arrow>
          <span>
            {moment(data.created).toNow(true)}
          </span>
        </Tooltip>
      );
    case 'message':
      const handleClose = () => {
        setThreadModalOpen(false);
        handleRefresh(true);
      };

      return (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Tooltip
            title={
              data.new_message_count > 0
                ? `${data.new_message_count} ${t('Unread messages')}`
                : t('Open chat')
            }
            enterDelay={500}
            enterNextDelay={500}
            arrow
          >
            <IconButton
              color="inherit"
              onClick={() => {
                setThreadModalOpen(true);
              }}
            >
              <Badge
                badgeContent={data.new_message_count}
                color="info"
                invisible={data.new_message_count === 0}
              >
                {data.new_message_count > 0 ? <BiMessageError size={24} /> : <BiMessageCheck size={24} />}
              </Badge>
            </IconButton>
          </Tooltip>
          <DialogChatBox
            open={threadModalOpen}
            threadId={data.message_thread_id}
            handleClose={handleClose}
          />
        </div>

      );
    case 'inactive':
      return (
        <Tooltip title={`${t('Last active on')} ${moment(data.last_active).locale(i18n.resolvedLanguage).format('lll')}`} arrow>
          <span>
            {moment().diff(moment(data.last_active), 'd')}
          </span>
        </Tooltip>
      );
    case 'medical_act': {
      return <MedicalActCell date={data.medical_act_created_date} id={data.latest_medical_act} />;
    }
    case 'dq':
      return (
        data.latest_dq_answer ? (
          <QuestionnaireSummaryButton
            date={data.latest_dq_answer.timestamp}
            score={data.latest_dq_answer.score || null}
            recordId={data.latest_dq_answer.id}
            hasScore
          />
        ) : (
          <div>
            {display}
          </div>
        )
      );
    case 'reminder':
      return (
        data.active_reminder_count > 0
        && (
          <>
            <Tooltip
              title={t('activeReminder', { count: data.active_reminder_count })}
              enterDelay={500}
              enterNextDelay={500}
              arrow
            >
              <div>
                <ColoredCircle
                  borderColor="warning"
                  onClick={(e) => {
                    e.stopPropagation();
                    setReminderModalOpen(true);
                  }}
                >
                  <Typography>
                    {data.active_reminder_count}
                  </Typography>
                </ColoredCircle>
              </div>
            </Tooltip>
            <ReminderModal
              patient={{
                uuid: data.id,
                firstName: data.first_name,
                lastName: data.last_name,
              }}
              handleClose={(e) => {
                e.stopPropagation();
                setReminderModalOpen(false);
              }}
              open={reminderModalOpen}
            />
          </>
        )
      );
    case 'steps':
      if (data.latest_steps) {
        const { value, timestamp } = data.latest_steps;

        latestDate = timestamp;
        display = value;
      }

      return (
        <VitalCell
          timestamp={latestDate}
          outsideThresholds={outsideThresholds}
        >
          {display}
        </VitalCell>
      );
    case 'restingHeartRate':
      if (data.latest_heart_rate) {
        const { value, timestamp } = data.latest_heart_rate;

        if (data.temperature_threshold) {
          outsideThresholds = checkIfOutsideThresholds(
            value.value,
            data.heart_rate_threshold.min,
            data.heart_rate_threshold.max,
          );
        }

        latestDate = timestamp;
        display = value;
      }

      return (
        <VitalCell
          timestamp={latestDate}
          outsideThresholds={outsideThresholds}
        >
          {display}
        </VitalCell>
      );
    case 'weight':
      if (data.latest_weight) {
        const { value, timestamp } = data.latest_weight;

        if (data.weight_threshold) {
          outsideThresholds = checkIfOutsideThresholds(
            value,
            data.weight_threshold.min,
            data.weight_threshold.max,
          );
        }

        latestDate = timestamp;
        display = value;
      }

      return (
        <VitalCell
          timestamp={latestDate}
          outsideThresholds={outsideThresholds}
        >
          {display}
        </VitalCell>
      );
    default:
      if (type in data) {
        // Custom data is aggregated
        if (data[type] === null || !currentConfig.summary) {
          display = '---';
        } else {
          const { value, unit, end_date } = currentConfig.summary;
          const startDate = moment().subtract(value, unit).format('lll');
          const end = end_date ? moment(end_date).format('lll') : t('now');
          customTooltip = `${t('Aggregated')} ${t('between')} ${startDate} ${t('and')} ${end}`;
          display = data[type];
        }
      } else if (data[`latest_${type}`]) {
        const { value, timestamp } = data[`latest_${type}`];

        if (data[`${type}_threshold`]) {
          outsideThresholds = checkIfOutsideThresholds(
            value.value,
            data[`${type}_threshold`].min,
            data[`${type}_threshold`].max,
          );
        }

        latestDate = timestamp;
        display = value.value;
      }

      return (
        <VitalCell
          timestamp={latestDate}
          outsideThresholds={outsideThresholds}
          customTooltip={customTooltip}
        >
          {display}
        </VitalCell>
      );
  }
};

CellContentSelector.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default CellContentSelector;
