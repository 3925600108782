import React from 'react';
import RichTextEditor from '../RichTextEditor';
import { FormHelperText, FormControl } from '@mui/material';

type PropsType = {
  formik: any;
  name: string;
  label: string;
  autoSave?: (e: any) => void;
};

const RichFormText = (props: PropsType) => {
  const {
    formik, name, label, autoSave,
  } = props;

  return (
    <FormControl 
      fullWidth 
      error={formik.touched[name] && Boolean(formik.errors[name])} 
      margin="normal"
    >
      <RichTextEditor
        field={formik.getFieldProps(name)}
        form={formik}
        placeholder={label}
        autoSave={autoSave}
      />
      {formik.touched[name] && formik.errors[name] && (
        <FormHelperText>{formik.errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};

export default RichFormText;
