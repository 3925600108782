import {
  Box, Toolbar, useMediaQuery,
} from '@mui/material';
import React from 'react';
import AppBarLogo from './AppBarLogo';

const AppBarUnregistered = ({ children }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box
      display="flex"
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
      }}
    >
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.background.paper,
          width: '100%',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'fixed',
        }}
      >
        <Toolbar sx={{ height: 60 }}>
          {!isMobile && (
            <AppBarLogo />
          )}
        </Toolbar>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: '64px',
          width: 'calc(100% - 245px)',
          overflow: 'auto',
          bgcolor: (theme) => theme.palette.background.default,
          px: '1rem',
          pt: 3,
          minHeight: 'calc(100vh - 90px)',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AppBarUnregistered;
