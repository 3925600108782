import {
  Card,
  Typography,
  Box,
  Stack,
  Tooltip,
  IconButton,
} from '@mui/material';
import { FiEdit } from 'react-icons/fi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmailConfirmation from '../components/invitations/EmailConfirmation';
import TokenValidation from '../components/invitations/TokenValidation';
import Layout from '../components/Layout';
import { emptyObject } from '../helper-functions';
import AppBarLogo from '../components/AppBarLogo';

const Invitation = () => {
  const { t } = useTranslation();
  const [enableToken, setEnableToken] = useState(false);
  const [emailValidation, setEmailValidation] = useState(null);

  useEffect(() => {
    if (!emptyObject(emailValidation)) {
      setEnableToken(true);
    }
  }, [emailValidation]);

  const handleChangeEmail = () => {
    setEnableToken(false);
    setEmailValidation(null);
  };

  return (
    <Layout width="sm">
      <Card sx={{ mt: 10, p: 4 }}>
        <Stack spacing={2} alignItems="center">
          <AppBarLogo />
          {enableToken ? (
            <>
              <Typography variant="h6" align="center">
                {t('Please enter the code sent to your email address')}:
              </Typography>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                }}
              >
                <Typography variant="body1" align="center">
                  {emailValidation}
                </Typography>
                <Tooltip title={t('Change Email')}>
                  <IconButton
                    onClick={handleChangeEmail}
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    <FiEdit size={16} />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          ) : (
            <Typography variant="h6" align="center">
              {t('Please enter your email address to receive an invitation code.')}
            </Typography>
          )}
          <Box sx={{ width: '100%', maxWidth: 400 }}>
            {enableToken ? (
              <TokenValidation email={emailValidation} />
            ) : (
              <EmailConfirmation setEmail={setEmailValidation} />
            )}
          </Box>
        </Stack>
      </Card>
    </Layout>
  );
};

export default Invitation;
