import {
  Box,
  Button,
  Checkbox,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { usePatientWebSocket } from '../../../hooks';
import { greyboxApiActions } from '../../../redux/api';
import GenerateReportDialog from './GenerateReportDialog';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const Reports = ({ patientUuid }) => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { patientReport } = greyboxApiActions;
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteReport] = patientReport.delete();
  const { data = { results: [], count: 0 }, isFetching, refetch } = patientReport.list({
    page: page + 1,
    page_size: rowsPerPage,
    patient: patientUuid,
    clinic: clinic.id,
  });

  const { lastJsonMessage } = usePatientWebSocket();

  useEffect(() => {
    if (lastJsonMessage && lastJsonMessage.entity === 'PatientReport') {
      refetch();
    }
  }, [lastJsonMessage]);

  const handleCheckbox = (value) => {
    setSelected((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );
  };

  const handleDelete = () => {
    selected.forEach((item) => deleteReport(item));
    setSelected([]);
  };

  const columns = [
    { id: 'created', label: t('Date'), format: (value) => moment(value).format('YYYY-MM-DD HH:mm') },
    { id: 'name', label: t('Name') },
    { id: 'created_by', label: t('Created by'), format: (value) => `${value.first_name} ${value.last_name}` },
    { id: 'status', label: t('Status'), format: (value) => t(value) },
  ];

  return (
    <>
      <Box sx={{ width: '100%', p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography
            variant="h4"
            sx={{
              mr: 1,
              fontWeight: 'bold',
              color: 'text.secondary',
            }}
          >
            {t('Reports')}
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid',
              borderColor: 'divider',
              px: 1,
            }}
          >
            <Button
              sx={{ mr: 2 }}
              variant="outlined"
              startIcon={<NoteAddOutlinedIcon />}
              size="small"
              onClick={() => setOpen(true)}
            >
              {t('Generate')}
            </Button>
            <Button
              disabled={selected.length === 0}
              variant="outlined"
              color="error"
              size="small"
              startIcon={<DeleteOutlineIcon />}
              onClick={handleDelete}
            >
              {t('Delete')}
            </Button>
            <Box sx={{ marginLeft: 'auto' }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={data.count}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                labelRowsPerPage={t('Rows per page')}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${t('of')} ${count}`
                }
              />
            </Box>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? Array.from({ length: rowsPerPage }).map((_, idx) => (
                    <TableRow key={idx}>
                      <TableCell padding="checkbox">
                        <Skeleton variant="rectangular" width={24} height={24} />
                      </TableCell>
                      {columns.map((column, colIdx) => (
                        <TableCell key={colIdx}>
                          <Skeleton variant="text" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : data.results.length > 0
                ? data.results.map((row, idx) => (
                    <TableRow key={idx} hover>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={selected.includes(row.id)}
                          onChange={() => handleCheckbox(row.id)}
                        />
                      </TableCell>
                      {columns.map((column) => (
                        <TableCell key={column.id}>
                          {column.format ? column.format(row[column.id]) : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1} align="center">
                        <Typography variant="body1">{t('No reports available')}</Typography>
                      </TableCell>
                    </TableRow>
                  )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <GenerateReportDialog
        open={open}
        handleClose={() => setOpen(false)}
        patientUuid={patientUuid}
      />
    </>
  );
};

export default Reports;
