import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { tenantKeyConfig, tenantHeaderConfig } from 'environmentConfig';


const hostName = window.location.hostname;
const keyConfig = tenantKeyConfig[hostName]
const headerConfig = tenantHeaderConfig[hostName];

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    connectionString: `instrumentationKey=${keyConfig}`,
    extensions: [reactPlugin],
    correlationHeaderDomains: [headerConfig],
    enableCorsCorrelation: true,
    disableFetchTracking: false,
    enableAjaxErrorStatusText: true,
    enableAjaxPerfTracking: true,
    enableAutoRouteTracking: true,
    enableSendLiveMetrics: true,
    samplingPercentage: 10,
    disableInstrumentationKeyValidation: window.location.hostname === 'localhost',
  },
});

ai.loadAppInsights();

export default (Component) => withAITracking(reactPlugin, Component);
export const { appInsights } = ai;
export { reactPlugin };
