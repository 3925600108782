import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { Question, Answers } from '../../../../types';

type Props = {
  question: Question;
  setAnswers: (id: number, answer: string) => void;
  answers: Answers;
  isCurrent: boolean;
};

type SequenceItem = string;

const useStyles = makeStyles((theme: Theme) => ({
  radioText: {
    display: 'flex',
    alignItems: 'center',
  },
  radioFormContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  radioBtnContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  radioBtn: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

/**
 * Panel displaying a rated question. The question have a start, end and increment value that
 * are displayed as radio buttons. The question can also optionally have a start and an end label
 */
const GradationPanel: React.FC<Props> = ({
  question, setAnswers, answers, isCurrent,
}: Props) => {
  const [selected, setSelected] = useState<string | null>(null);
  const info = question.ra_question;
  const [sequence, setSequence] = useState<SequenceItem[]>([]);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  useEffect(() => {
    if (answers[question.id] && selected !== answers[question.id]) {
      setSelected(answers[question.id]);
    }
  }, [question, answers]);

  useEffect(() => {
    const seq: SequenceItem[] = [];
    for (
      let i = Number(info.start_range); i <= Number(info.end_range); i += Number(info.increment)
    ) {
      seq.push(i.toString());
    }
    setSequence(seq);
  }, [info]);

  useEffect(() => {
    if (selected) {
      setAnswers(question.id, selected);
    }
  }, [selected]);

  const handleSelection = (value: string) => {
    setSelected(value);
  };

  return (
    <>
      <Typography sx={{ width: '100%', mb: 1 }}>
        <Markdown>
          {info.ra_question_text}
        </Markdown>
      </Typography>
      {isMobile && (
        <Box display="flex" flexDirection="column">
          {info.start_label && (
            <Typography variant="caption">
              {`${info.start_range} → ${info.start_label}`}
            </Typography>
          )}
          {info.end_label && (
            <Typography variant="caption">
              {`${info.end_range} → ${info.end_label}`}
            </Typography>
          )}
        </Box>
      )}
      <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
        {sequence.map((number, index) => (
          <Button
            key={number}
            data-cy={isCurrent ? `answer-${index}` : ''}
            variant={selected === number ? 'contained' : 'outlined'}
            color={selected === number ? 'primary' : 'secondary'}
            sx={{ width: '60px', height: '60px', m: 0.5 }}
            onClick={() => handleSelection(number)}
          >
            {number}
          </Button>
        ))}
      </Box>
      {!isMobile && (
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Typography variant="caption">
            {info.start_label}
          </Typography>
          <Typography variant="caption">
            {info.end_label}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default GradationPanel;
