import React, { useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Box,
  IconButton,
  Grid,
  Divider,
} from '@mui/material';
import { FaMicrophone, FaStop, FaTimes, FaUpload } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import AudioRecorder from './AudioRecorder';

const AudioPickerDialog = ({ open, onClose, onAudioSelected }) => {
  const { t } = useTranslation();

  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const audioRecorderRef = useRef(null);

  const handleStartRecording = () => {
    audioRecorderRef.current.startRecording();
    setIsRecording(true);
  };

  const handleStopRecording = () => {
    audioRecorderRef.current.stopRecording();
    setIsRecording(false);
  };

  const handleDataAvailable = (audioBlob) => {
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
      onAudioSelected(audioBlob);
    }
    setIsProcessing(false);
    handleClose(); // Auto-close the dialog after recording
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onAudioSelected(file);
      const url = URL.createObjectURL(file);
      setAudioUrl(url);
      handleClose(); // Auto-close the dialog after file selection
    }
  };

  const handleClose = () => {
    onClose();
    setAudioUrl(null);
    setIsRecording(false);
    setIsProcessing(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('Select or Record Audio')}</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={5}>
              <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" p={2}>
                <Typography variant="h6" gutterBottom>{t('Select an Audio File')}</Typography>
                <input
                  accept="audio/*"
                  style={{ display: 'none' }}
                  id="audio-file-input"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="audio-file-input">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<FaUpload />}
                  >
                    {t('Choose File')}
                  </Button>
                </label>
              </Box>
            </Grid>
            {/* Divider for larger screens */}
            <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' } }} />
            <Grid item xs={12} md={5}>
              <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" p={2}>
                <Typography variant="h6" gutterBottom>{t('Record Audio')}</Typography>
                {isProcessing ? (
                  <Box display="flex" alignItems="center" mt={2}>
                    <CircularProgress size={24} />
                    <Typography variant="body2" ml={2}>
                      {t('Processing...')}
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center" mt={2}>
                    <IconButton
                      color={isRecording ? 'secondary' : 'primary'}
                      onClick={isRecording ? handleStopRecording : handleStartRecording}
                      size="large"
                    >
                      {isRecording ? <FaStop /> : <FaMicrophone />}
                    </IconButton>
                    <Typography variant="body2" ml={2}>
                      {isRecording ? t('Recording...') : t('Click to start recording')}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          {audioUrl && (
            <Box mt={2} width="100%">
              <Typography variant="subtitle1" textAlign="center">{t('Preview')}:</Typography>
              <audio controls src={audioUrl} style={{ width: '100%' }} />
            </Box>
          )}
        </Box>
        <AudioRecorder
          ref={audioRecorderRef}
          onDataAvailable={handleDataAvailable}
          onStartRecording={() => setIsRecording(true)}
          onStopRecording={() => {
            setIsRecording(false);
            setIsProcessing(true);
          }}
          maxRecordingTime={1000*60*10} // 10 mins
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button onClick={handleClose} color="primary" startIcon={<FaTimes />}>
          {t('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AudioPickerDialog;
