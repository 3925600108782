// PatientActionPanel.js

import ErrorIcon from '@mui/icons-material/Error';
import MessageIcon from '@mui/icons-material/Message';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiMailAddLine, RiMailSendLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { usePatientWebSocket } from '../../../hooks';
import { greyboxApiActions } from '../../../redux/api';
import AlertModal from '../../alert/AlertModal';
import { DialogChatBox } from '../../chat';
import PatientLinkInvitation from '../../invitations/PatientLinkInvitation';
import ReminderModal from '../components/ReminderModal';
import ProfilePictureUploadDialog from './ProfilePictureUploadDialog';

const PatientActionPanel = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const { uuid } = useParams();
  const { account, patientDashboard, diagnosis, invitation, media } = greyboxApiActions;
  const { access } = user;
  const { clinic } = useSelector((state) => state.clinic);

  const [chatOpen, setChatOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [reminderOpen, setReminderOpen] = useState(false);
  const [invitationOpen, setInvitationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profilePictureOpen, setProfilePictureOpen] = useState(false);
  const [sendMedia] = media.add();
  const [updateAccount] = account.update();
  const { lastJsonMessage } = usePatientWebSocket();

  const { data, isFetching, refetch } = account.get(uuid);

  const clinicInvitation = useMemo(
    () => data?.invitations?.find((inv) => inv.clinic === clinic.id),
    [data, clinic],
  );

  const [updateInvitation] = invitation.update();
  const diagnosisList = diagnosis.list({ clinic: clinic.id });
  const patientInfo = patientDashboard.list(
    {
      id: uuid,
      fields: access === 'PT' ? 'alert,reminder,message' : 'message',
      clinic: clinic.id,
    }
  );

  useEffect(() => {
    if (
      lastJsonMessage !== null &&
      ['Alert', 'Message', 'Treatment_Plan_Reminder'].includes(lastJsonMessage.entity)
    ) {
      patientInfo.refetch();
    }
  }, [lastJsonMessage]);

  const handleUpdateInvitation = async () => {
    if (clinicInvitation && clinicInvitation.status === 'pending') {
      setIsLoading(true);
      try {
        await updateInvitation({
          id: clinicInvitation.uuid,
          modifyDate: moment().toISOString(true),
          feedback: { success: t('Invitation sent') },
        });
      } catch (error) {
        console.error('Failed to update invitation:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setInvitationOpen(true);
    }
  };

  const patientName = useMemo(() => {
    if (data) {
      return data.firstName ? `${data.firstName} ${data.lastName}` : '---';
    }
    return '---';
  }, [data]);
  
  const hinNumber = data?.hin?.hin_number;
  const birthDate = data?.birthDate ? moment(data.birthDate).format('YYYY-MM-DD') : null;

  const patientDiagnoses = useMemo(() => {
    if (user.access !== 'PT' || !diagnosisList.data) {
      return null;
    }

    // Create a lookup map for quick access to diagnosis names
    const diagMap = new Map();
    diagnosisList.data.forEach((diag) => {
      diagMap.set(diag.diagnosis_key, diag.diagnosis_name);
      diagMap.set(diag.uuid, diag.diagnosis_name);
    });

    // Combine diagnosis keys from both diagnosis and secondary_diagnosis
    const diagKeys = [
      ...(data?.diagnosis || []),
      ...(data?.secondary_diagnosis || []),
    ];

    // Map keys to diagnosis names using the lookup map
    const diagnosisNames = diagKeys
      .map((diagKey) => diagMap.get(diagKey))
      .filter(Boolean);

    return diagnosisNames.join(', ');
  }, [
    user.access,
    diagnosisList.data,
    data?.diagnosis,
    data?.secondary_diagnosis,
  ]);


  const renderActionButton = () => {
    const invitationPending = clinicInvitation?.status === 'pending';
    const buttonText = isLoading
      ? t('Processing...')
      : invitationPending
        ? t('Resend Invitation')
        : t('Send Invitation');
    const buttonIcon = isLoading ? (
      <CircularProgress size={20} color="inherit" />
    ) : invitationPending ? (
      <RiMailSendLine />
    ) : (
      <RiMailAddLine />
    );

    return (
      <Button
        variant="contained"
        startIcon={buttonIcon}
        sx={{
          '&:hover, &.Mui-focusVisible': { backgroundColor: 'primary.dark' },
          backgroundColor: invitationPending ? 'success.main' : 'primary.main',
          color: '#FFFFFF',
        }}
        onClick={handleUpdateInvitation}
        disabled={isLoading}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <Paper>
      <Box
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {isFetching ? (
          <Skeleton variant="circular" width={100} height={100} />
        ) : (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => setProfilePictureOpen(true)}
                sx={{ backgroundColor: 'white', p: 0.5 }}
              >
                <PhotoCamera />
              </IconButton>
            }
          >
            <Avatar
              alt={patientName}
              src={data?.user?.profile_picture_url}
              sx={{ width: 100, height: 100 }}
            />
          </Badge>
        )}
        {isFetching ? (
          <Skeleton variant="text" width={200} height={40} />
        ) : (
          <Typography variant="h5">{patientName}</Typography>
        )}
        {isFetching && !hinNumber ? (
          <Skeleton variant="text" width={100} height={25} />
        ) : (
          hinNumber && <Typography variant="body2">{hinNumber}</Typography>
        )}
        {isFetching && !birthDate ? (
          <Skeleton variant="text" width={100} height={25} />
        ) : (
          birthDate && <Typography variant="body2">{birthDate}</Typography>
        )}
        {!diagnosisList.isFetching && patientDiagnoses && (
          <Typography variant="caption" color="textSecondary" align="center">
            {patientDiagnoses}
          </Typography>
        )}
      </Box>

      <Box display="flex" sx={{ m: 1 }} flexDirection="row" justifyContent="space-evenly">
        {clinic.config.features_enable.alert && access === 'PT' && (
          patientInfo.isFetching ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <Tooltip title={t('Follow-up')} arrow enterDelay={500} enterNextDelay={500}>
              <Badge badgeContent={patientInfo.data?.active_alert_count} color="error">
                <Avatar
                  sx={{
                    bgcolor: 'error.main',
                    color: 'error.contrastText',
                    '&:hover': {
                      bgcolor: 'error.dark',
                      cursor:
                        !patientInfo.data || patientInfo.data.active_alert_count === 0
                          ? 'default'
                          : 'pointer',
                    },
                    opacity:
                      !patientInfo.data || patientInfo.data.active_alert_count === 0 ? 0.5 : 1,
                  }}
                  onClick={() => {
                    if (patientInfo.data && patientInfo.data.active_alert_count !== 0) {
                      setAlertOpen(true);
                    }
                  }}
                >
                  <ErrorIcon />
                </Avatar>
              </Badge>
            </Tooltip>
          )
        )}

        {clinic.config.features_enable.reminder && access === 'PT' && (
          patientInfo.isFetching ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <Tooltip title={t('Reminders')} arrow enterDelay={500} enterNextDelay={500}>
              <Badge badgeContent={patientInfo.data?.active_reminder_count} color="warning">
                <Avatar
                  sx={{
                    bgcolor: 'warning.main',
                    color: 'warning.contrastText',
                    '&:hover': {
                      bgcolor: 'warning.dark',
                      cursor:
                        !patientInfo.data || patientInfo.data.active_reminder_count === 0
                          ? 'default'
                          : 'pointer',
                    },
                    opacity:
                      !patientInfo.data || patientInfo.data.active_reminder_count === 0 ? 0.5 : 1,
                  }}
                  onClick={() => {
                    if (patientInfo.data && patientInfo.data.active_reminder_count !== 0) {
                      setReminderOpen(true);
                    }
                  }}
                >
                  <NotificationsIcon />
                </Avatar>
              </Badge>
            </Tooltip>
          )
        )}

        {clinic.config.features_enable.message && data?.invitation_status === 'accepted' && (
          patientInfo.isFetching ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <Tooltip title={t('Messages')} arrow enterDelay={500} enterNextDelay={500}>
              <Badge badgeContent={patientInfo.data?.new_message_count} color="info">
                <Avatar
                  sx={{
                    bgcolor: 'info.main',
                    color: '#FFFFFF',
                    '&:hover': {
                      bgcolor: 'info.dark',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => setChatOpen(true)}
                >
                  <MessageIcon />
                </Avatar>
              </Badge>
            </Tooltip>
          )
        )}
      </Box>

      {!isFetching && access === 'PT' && data?.invitation_status !== 'accepted' && (
        <Box display="flex" sx={{ m: 1 }} flexDirection="row" justifyContent="space-evenly">
          {renderActionButton()}
          <PatientLinkInvitation
            patient={data}
            open={invitationOpen}
            handleClose={() => {
              setInvitationOpen(false);
              refetch();
            }}
          />
        </Box>
      )}

      {reminderOpen && (
        <ReminderModal
          open={reminderOpen}
          handleClose={() => setReminderOpen(false)}
          patient={{ uuid: uuid }}
          link={false}
        />
      )}

      {alertOpen && (
        <AlertModal
          open={alertOpen}
          handleClose={() => setAlertOpen(false)}
          patient={{ uuid: uuid }}
          link={false}
        />
      )}

      {chatOpen && (
        <DialogChatBox
          open={chatOpen}
          handleClose={() => setChatOpen(false)}
          threadId={patientInfo.data?.message_thread_id}
        />
      )}

      {/* Profile Picture Upload Dialog */}
      <ProfilePictureUploadDialog
        open={profilePictureOpen}
        handleClose={() => setProfilePictureOpen(false)}
        onSave={async (file) => {
          try {
            const newMedia = new FormData();
            newMedia.append('file', file);
            newMedia.append('clinic', clinic.id);
            newMedia.append('type', 'image');
            newMedia.append('patient', uuid);
            const mediaData = await sendMedia({ body: newMedia });
            const body = { user: { profile_picture: mediaData.data.id } };
            await updateAccount({ id: uuid, body });
          } catch (error) {
            console.error('Failed to upload profile picture:', error);
          }
        }}
      />
    </Paper>
  );
};

export default PatientActionPanel;
