import React from 'react';
import { MenuItem, TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type DurationProps = {
  formik: any;
  name: string;
  label: string;
  shortOptions?: boolean;
};

const Duration = (props: DurationProps) => {
  const { formik, name, label, shortOptions = false } = props;
  const { t } = useTranslation();

  const DURATION_OPTIONS_SHORT = [
    { label: t('None'), value: null },
    { label: t('15 minutes'), value: moment.duration(15, 'minutes') },
    { label: t('30 minutes'), value: moment.duration(30, 'minutes') },
    { label: t('45 minutes'), value: moment.duration(45, 'minutes') },
    { label: t('1 hour'), value: moment.duration(1, 'hours') },
    { label: t('1 hour 15 minutes'), value: moment.duration(1, 'hours').add(15, 'minutes') },
    { label: t('1 hour 30 minutes'), value: moment.duration(1, 'hours').add(30, 'minutes') },
    { label: t('1 hour 45 minutes'), value: moment.duration(1, 'hours').add(45, 'minutes') },
    { label: t('2 hours'), value: moment.duration(2, 'hours') },
  ];

  const DURATION_OPTIONS = [
    { label: t('None'), value: null },
    { label: t('15 minutes'), value: moment.duration(15, 'minutes') },
    { label: t('30 minutes'), value: moment.duration(30, 'minutes') },
    { label: t('45 minutes'), value: moment.duration(45, 'minutes') },
    { label: t('1 hour'), value: moment.duration(1, 'hours') },
    { label: t('4 hours'), value: moment.duration(4, 'hours') },
    { label: t('1 day'), value: moment.duration(1, 'days') },
    { label: t('1 week'), value: moment.duration(1, 'weeks') },
  ];

  const options = shortOptions ? DURATION_OPTIONS_SHORT : DURATION_OPTIONS;

  // Assign unique keys to options
  const optionsWithKeys = options.map((option, idx) => ({
    ...option,
    key: idx,
  }));

  // Determine the selected key based on formik.values[name]
  const selectedOption =
    optionsWithKeys.find((option) => {
      if (option.value === null) {
        return formik.values[name] === null || formik.values[name] === '';
      }
      return (
        moment.duration(option.value).asMilliseconds() ===
        moment.duration(formik.values[name]).asMilliseconds()
      );
    }) || optionsWithKeys[0];

  const handleChange = (event) => {
    const selectedKey = parseInt(event.target.value, 10);
    const selectedOption = optionsWithKeys.find((option) => option.key === selectedKey);
    if (selectedOption) {
      formik.setFieldValue(name, selectedOption.value);
    }
  };

  return (
    <Box sx={{ width: '100%', mx: 0, my: 1 }}>
      <TextField
        select
        fullWidth
        sx={{ mb: 2 }}
        label={label}
        value={selectedOption.key}
        onChange={handleChange}
      >
        {optionsWithKeys.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default Duration;
