import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import FormSelect from './FormSelect';

interface PropsType {
  formik: any;
  sx?: any;
  required?: boolean;
}

const Sex: React.FC<PropsType> = ({ formik, sx = null, required = false }) => {
  const { t } = useTranslation();

  const genderOptions = [
    { value: 'M', label: t('Male') },
    { value: 'F', label: t('Female') },
    { value: 'X', label: t('Non-Binary') },
    { value: 'U', label: t('Not Specified') },
  ];

  return (
    <FormSelect
      formik={formik}
      sx={sx}
      required={required}
      name="gender"
      label={t('Sex')}
    >
      {genderOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </FormSelect>
  );
};

export default Sex;
