import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup'; // Import Yup
import React from 'react';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../redux/api';

const EmailConfirmation = (props) => {
  const { setEmail } = props;
  const { t } = useTranslation();
  const { emailValidation } = greyboxApiActions;
  const [sendEmail] = emailValidation.add();

  // Define the Yup validation schema
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('Invalid email address'))
      .required(t('Email is required')),
  });

  const initialValues = { email: '' };

  const onSubmit = (value, formikBag) => {
    sendEmail({ body: value })
      .unwrap()
      .then(() => {
        setEmail(value.email);
      })
      .catch((error) => {
        if (error.status === 400) {
          formikBag.setErrors(error.data);

          if (error.data.non_field_errors) {
            formikBag.setStatus({ generalError: error.data.non_field_errors });
          }

        } else {
          formikBag.setStatus({ generalError: t('An unexpected error occurred.') });
        }
      })
      .finally(() => {
        formikBag.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema} // Use the Yup schema here
      onSubmit={onSubmit}
    >
      {(formikBag) => (
        <form onSubmit={formikBag.handleSubmit}>
          <Box display="flex" alignItems="center" flexDirection="column" margin="none">
            <TextField
              name="email"
              type="email"
              fullWidth
              value={formikBag.values.email}
              onChange={formikBag.handleChange}
              onBlur={formikBag.handleBlur} // Add handleBlur
              label={t('Email')}
              placeholder={t('Email')}
              required
              error={Boolean(formikBag.touched.email && formikBag.errors.email)}
              helperText={formikBag.touched.email && formikBag.errors.email}
            />
          </Box>
          {formikBag.status && formikBag.status.generalError && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {formikBag.status.generalError}
            </Typography>
          )}
          <Box display="flex" alignItems="center" flexDirection="column" sx={{ mt: 1 }}>
            <LoadingButton
              size="large"
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              loading={formikBag.isSubmitting}
            >
              {t('Send')}
            </LoadingButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default EmailConfirmation;
