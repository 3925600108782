import { Editable, useEditor } from "@wysimark/react";
import React from 'react';

import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormControl, FormHelperText } from "@mui/material";


const MarkdownEditor = ({ name }) => {
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const editor = useEditor({ minHeight: '500px' });

    const handleWysimarkChange = (content) => {
        helpers.setValue(content);
    };

    return (
        <FormControl
            fullWidth
            error={Boolean(meta.touched && meta.error)}
            sx={{ mt: 2 }}
        >
            <Editable editor={editor} value={field.value} onChange={handleWysimarkChange} throttleInMs={500} />
            {meta.touched && meta.error && (
                <FormHelperText>{meta.error}</FormHelperText>
            )}
        </FormControl>
    );
};

export default MarkdownEditor;
