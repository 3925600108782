import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Fade, Link, TextField, Typography } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { greyboxApiActions } from '../../../../redux/api';
import { RootState } from '../../../../redux/store';
import { Answers, Question } from '../../../../types';
import FeedbackMessage from './FeedbackMessage';
import { parseAnswerText } from './utils';

const answerParser = (type: string, answer: string) => {
  if (type === 'TF') {
    return answer === 'T';
  }
  return answer;
};

const questionIdParser = (question: Question) => {
  const type = question.type_of_q;
  return question[`${type.toLowerCase()}_question`].id;
};

const ThankYouMessage = ({ onComplete, res }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (onComplete) {
      console.debug('ThankYouMessage component mounted with onComplete callback and res:', res);
      const timeoutId = setTimeout(() => {
        console.debug('Calling onComplete with res:', res);
        onComplete(res);
      }, 2000);
      return () => {
        console.debug('Cleaning up timeout with ID:', timeoutId);
        clearTimeout(timeoutId);
      };
    }
  }, [onComplete, res]);

  return (
    <Fade in timeout={1000}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="h6">{t('Thank you for completing the questionnaire')}</Typography>
        {!onComplete && <Typography>{t('You can safely close this window')}</Typography>}
      </Box>
    </Fade>
  );
};

const QuestionBox = ({ question, children, handleBackFromSummary }) => (
  <Box
    sx={{
      p: 2,
      border: (theme) => `2px dashed ${theme.palette.divider}`,
      m: 1,
      borderRadius: 1,
    }}
  >
    <Box display="flex" alignItems="center" minHeight="40px">
      <Link onClick={() => handleBackFromSummary(question.index)} sx={{ cursor: 'pointer' }}>
        <Typography variant="h6">{question.text}</Typography>
      </Link>
      <KeyboardReturnIcon sx={{ ml: 1 }} />
    </Box>
    {children}
  </Box>
);

type CompletionPanelProps = {
  patientUuid: string;
  tokenId: string;
  questions: Question[];
  answers: Answers;
  others: Answers;
  handleBackFromSummary: (index: number) => void;
  onComplete: (res: string) => void;
};

const CompletionPanel = (props: CompletionPanelProps) => {
  const {
    questions,
    answers,
    handleBackFromSummary,
    onComplete,
    others,
    patientUuid = null,
    tokenId = null,
  } = props;
  const { t } = useTranslation();
  const [openMessage, setOpenMessage] = useState(null);
  const [date, setDate] = useState(new Date());
  const location = useLocation();
  const { questionnaireAnswers } = greyboxApiActions;
  const [addAnswer, { isLoading }] = questionnaireAnswers.add();
  const { access } = useSelector((state: RootState) => state.user);
  const [feedback, setFeedback] = useState(null);
  const [submissionError, setSubmissionError] = useState('');

  const handleSubmission = async () => {
    console.debug('Submission started');
    // If we're in test mode (from clinic-settings) don't POST the answers
    if (location.pathname.includes('clinic-settings')) {
      console.debug('In clinic-settings mode, setting open message');
      setOpenMessage(true);
      return;
    }

    try {
      const parsedAnswers = questions
        .map((question) => {
          const type = question.type_of_q;
          const otherAnswer = others[question.id];

          console.debug(
            `Parsing answer for question ID: ${question.id}, type: ${type}, otherAnswer: ${otherAnswer}`
          );

          return {
            base_question: question.id,
            question: questionIdParser(question),
            answer: answerParser(type, answers[question.id]),
            type: type,
            other_answer: otherAnswer,
          };
        })
        .filter((answer) => {
          const isValid = answer.answer !== '';
          console.debug(
            `Filtering answer for question ID: ${answer.base_question}, isValid: ${isValid}`
          );
          return isValid;
        });

      const params = {
        questionnaire: questions[0].questionnaire,
        answer_timestamp: date,
        answers: parsedAnswers,
      };

      if (patientUuid) {
        params['patient'] = patientUuid;
        console.debug(`Patient UUID set: ${patientUuid}`);
      }

      if (tokenId) {
        params['token'] = tokenId;
        console.debug(`Token ID set: ${tokenId}`);
      }

      console.debug('Submitting answers:', params);

      const res = await addAnswer({
        body: params,
      });

      if (res.error) {
        throw new Error(res.error);
      }

      if (res.data.feedback) {
        console.debug('Received feedback in response:', res.data.feedback);
        setFeedback(res.data);
      } else {
        console.debug('Open message response ID:', res.data.id);
        setOpenMessage(res.data.id.toString());
      }
    } catch (error) {
      console.error('Error during submission:', error);
      setSubmissionError(t('An error occurred during submission'));
    }
  };

  if (submissionError) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error" onClose={() => setSubmissionError('')}>
          {submissionError}
        </Alert>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <LoadingButton variant="contained" onClick={handleSubmission}>
            {t('Try Again')}
          </LoadingButton>
        </Box>
      </Box>
    );
  }

  if (openMessage) {
    return <ThankYouMessage onComplete={onComplete} res={openMessage} />;
  }

  if (feedback) {
    return <FeedbackMessage handleClose={onComplete} data={feedback} />;
  }

  const filteredQuestions = questions.filter(
    (question) => question.id in answers && answers[question.id] !== ''
  );

  return (
    <Box
      sx={{
        height: '100%',
        p: 2,
        mt: 2,
        width: '100%',
        maxWidth: 'lg',
      }}
    >
      <Typography sx={{ ml: 1 }}>
        <b>
          {`${t('Review your answers below')}. ${t(
            'Click on any question to edit your response'
          )}.`}
        </b>
      </Typography>
      {filteredQuestions.map((question, index) => (
        <AnswerSummary
          key={question.id}
          questions={questions}
          question={question}
          answers={answers}
          others={others}
          handleBackFromSummary={handleBackFromSummary}
          index={index}
        />
      ))}
      <Box display="flex" alignItems="center" justifyContent="flex-end" padding={2}>
        {access === 'PT' && (
          <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label={t('Date of completion')}
                value={moment(date)}
                onChange={(newDate) => setDate(newDate)}
                renderInput={(params) => <TextField {...params} size="small" sx={{ mr: 1 }} />}
              />
            </LocalizationProvider>
            <Box sx={{ mr: 2 }} />
          </>
        )}
        <LoadingButton variant="contained" loading={isLoading} onClick={handleSubmission}>
          {t('Submit')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

type AnswerSummaryProps = {
  question: Question;
  questions: Question[];
  answers: Answers;
  others: Answers;
  handleBackFromSummary: (index: number) => void;
  index: number;
};

const AnswerSummary = ({
  question,
  answers,
  handleBackFromSummary,
  index,
  others,
  questions,
}: AnswerSummaryProps) => {
  const { t } = useTranslation();
  const type = question.type_of_q;

  if (type === 'MC') {
    return (
      <QuestionBox
        handleBackFromSummary={handleBackFromSummary}
        question={{
          text: question.mc_question.mc_question_text,
          index: index,
        }}
      >
        <ul>
          {answers[question.id].split(',').map((answer) => (
            <li key={answer}>
              <Typography>
                {answer === 'OTHER'
                  ? others[question.id]
                  : parseAnswerText(
                      question.mc_question[`mc_question_choice_${answer.toLowerCase()}`],
                      questions,
                      answers
                    )}
              </Typography>
            </li>
          ))}
        </ul>
      </QuestionBox>
    );
  }

  if (type === 'TF') {
    return (
      <QuestionBox
        handleBackFromSummary={handleBackFromSummary}
        question={{
          text: question.tf_question.tf_question_text,
          index: index,
        }}
      >
        <Typography>{answers[question.id] === 'T' ? t('Yes') : t('No')}</Typography>
      </QuestionBox>
    );
  }

  if (type === 'QA') {
    return null;
  }

  if (type === 'RA') {
    return (
      <QuestionBox
        handleBackFromSummary={handleBackFromSummary}
        question={{
          text: question.ra_question.ra_question_text,
          index: index,
        }}
      >
        <Typography>{answers[question.id]}</Typography>
      </QuestionBox>
    );
  }

  // SA question
  return (
    <QuestionBox
      handleBackFromSummary={handleBackFromSummary}
      question={{
        text: question.sa_question.sa_question_text,
        index: index,
      }}
    >
      <Typography>{answers[question.id]}</Typography>
    </QuestionBox>
  );
};

export default CompletionPanel;
