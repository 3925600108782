import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useTranslation } from 'react-i18next';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';

const ProfilePictureUploadDialog = ({ open, handleClose, onSave }) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(null);
  const [scale, setScale] = useState(1.2);
  const editorRef = useRef(null);

  // Reset state when the dialog is closed
  useEffect(() => {
    if (!open) {
      setImage(null);
      setUploading(false);
      setScale(1.2);
    }
  }, [open]);

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setImage(acceptedFiles[0]);
    }
  };

  const handleScaleChange = (event, newValue) => {
    setScale(newValue);
  };

  const handleSave = () => {
    if (editorRef.current) {
      setUploading(true);
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob(
        (blob) => {
          const file = new File([blob], 'profile_picture.png', { type: 'image/png' });
          onSave(file); // Pass the file to the parent component
          setUploading(false);
          handleClose();
        },
        'image/png',
        1
      );
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('Change Profile Picture')}</DialogTitle>
      <DialogContent>
        {uploading ? (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {image ? (
              <>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <AvatarEditor
                    ref={editorRef}
                    image={image}
                    width={250}
                    height={250}
                    border={50}
                    borderRadius={125}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={scale}
                    rotate={0}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Slider
                    value={scale}
                    min={1}
                    max={3}
                    step={0.01}
                    onChange={handleScaleChange}
                    aria-labelledby="zoom-slider"
                  />
                </Box>
              </>
            ) : (
              <Dropzone onDrop={handleDrop} accept={{ 'image/*': [] }} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    sx={{
                      border: '2px dashed #ccc',
                      padding: '20px',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <input {...getInputProps()} />
                    <PhotoCamera sx={{ fontSize: 40 }} />
                    <p>{t('Drag and drop an image or click to select')}</p>
                  </Box>
                )}
              </Dropzone>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('Cancel')}
        </Button>
        {image && (
          <Button onClick={handleSave} color="primary" variant="contained">
            {t('Save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ProfilePictureUploadDialog;
