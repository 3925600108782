import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog, DialogContent, DialogContentText, DialogTitle,
  IconButton,
  Typography,
  Skeleton
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../redux/api';
import ChatBox from './ChatBox';

const DialogChatBox = (props) => {
  const { open, handleClose, threadId } = props;
  const { messageThread } = greyboxApiActions;
  const { t } = useTranslation();

  const { data, isFetching, isError } = messageThread.get(threadId);

  return (
    <Dialog open={open} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        handleClose();
      }
    }} fullWidth maxWidth="md">
      <DialogTitle
        sx={{
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {isFetching ? (
          <Skeleton variant="text" width={200} height={32} />
        ) : (
          <Typography variant="h6">{data.title}</Typography>
        )}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: 'primary.contrastText',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0, height: '600px' }}  >
        {isError ? (
          <DialogContentText>
            {t('There was an error loading the chat. Please try again later.')}
          </DialogContentText>
        ) : (
          <ChatBox threadId={threadId} isLoading={isFetching} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DialogChatBox;
