import TakecareLogo from '../../assets/takecare/Logo';
import Logo from '../../assets/takecare/Logo_Greybox.png'

const tenantMetadataConfig = {
  title: 'TAKECARE by Greybox',
  logo: Logo,
  JSXLogo: TakecareLogo,
};

export default tenantMetadataConfig;
