import { Typography, Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';

const AnswerFormatter = ({ question, data, hasTitle = true }) => {
  const { t } = useTranslation();

  if (!data) {
    return <Typography>{t('No answer')}</Typography>;
  }

  if (question.type_of_q === 'MC') {
    const answer = data.answers.find(
      (item) => item.question === question.mc_question.id && item.type === 'MC',
    );

    if (answer) {
      return (
        <Box sx={{ fontFamily: (theme) => theme.typography.fontFamily }}>
          {hasTitle && (
            <Markdown>
              {question.mc_question.mc_question_text}
            </Markdown>
          )}
          {Array.isArray(answer.answer) ? (
            <ul>
              {answer.answer.map((ans, idx) => (
                <li key={idx} style={{ fontWeight: 'bold' }}>
                  {question.mc_question[`mc_question_choice_${ans.toLowerCase()}`]}
                </li>
              ))}
            </ul>
          ) : (
            <Typography sx={{ fontWeight: 'bold' }}>
              {question.mc_question[`mc_question_choice_${answer.answer.toLowerCase()}`]}
            </Typography>
          )}
        </Box>
      );
    }
  } if (question.type_of_q === 'TF') {
    const answer = data.answers.find(
      (item) => item.question === question.tf_question.id && item.type === 'TF',
    );
    if (answer) {
      return (
        <Box sx={{ fontFamily: (theme) => theme.typography.fontFamily }}>
          {hasTitle && (
            <Markdown>
              {question.tf_question.tf_question_text}
            </Markdown>
          )}

          <Typography sx={{ fontWeight: 'bold' }}>
            {answer.answer ? t('Yes') : t('No')}
          </Typography>
        </Box>
      );
    }
  } if (question.type_of_q === 'SA') {
    const answer = data.answers.find(
      (item) => item.question === question.sa_question.id && item.type === 'SA',
    );
    if (answer) {
      return (
        <Box sx={{ fontFamily: (theme) => theme.typography.fontFamily }}>
          {hasTitle && (
            <Markdown>
              {question.sa_question.sa_question_text}
            </Markdown>
          )}
          <Typography sx={{ fontWeight: 'bold' }}>
            {answer.answer}
          </Typography>
        </Box>
      );
    }
  } if (question.type_of_q === 'RA') {
    const answer = data.answers.find(
      (item) => item.question === question.ra_question.id && item.type === 'RA',
    );
    if (answer) {
      return (
        <Box sx={{ fontFamily: (theme) => theme.typography.fontFamily }}>
          {hasTitle && (
            <Markdown>
              {question.ra_question.ra_question_text}
            </Markdown>
          )}
          <ul>
            <li style={{ fontWeight: 'bold' }}>
              {answer.answer}
            </li>
          </ul>
        </Box>
      );
    }
  }

  return null;
};

export default AnswerFormatter;
