// Auth Config
const tenantEnvironmentIds = {
  dev: '8e156aa2-1f63-4103-9325-2d09bb9c6625',
  staging: '0962c4ec-2f0e-4787-8aed-db939b1581ba',
  takecare: '235124e1-08cb-4f76-a3ca-98b2f0e4e807',
  hotfix: '235124e1-08cb-4f76-a3ca-98b2f0e4e807',
  localhost: '8e156aa2-1f63-4103-9325-2d09bb9c6625',
};

const tenantAuthority = {
  dev: 'https://takecareca.b2clogin.com/takecareca.onmicrosoft.com/B2C_1A_SIGNIN_DEV',
  staging: 'https://takecareca.b2clogin.com/takecareca.onmicrosoft.com/B2C_1A_SIGNIN_STAGING',
  takecare: 'https://takecareca.b2clogin.com/takecareca.onmicrosoft.com/B2C_1A_SIGNIN_PROD',
  hotfix: 'https://takecareca.b2clogin.com/takecareca.onmicrosoft.com/B2C_1A_SIGNIN_PROD',
  localhost: 'https://takecareca.b2clogin.com/takecareca.onmicrosoft.com/B2C_1A_SIGNIN_DEV',
};

const tenantScopes = {
  dev: ['openid', 'offline_access'],
  staging: ['openid', 'offline_access'],
  takecare: ['openid', 'offline_access'],
  hotfix: ['openid', 'offline_access'],
  localhost: ['openid', 'offline_access'],
};

const tenantScopesToken = {
  dev: [
    'https://takecareca.onmicrosoft.com/api/takecare.read',
    'https://takecareca.onmicrosoft.com/api/takecare.write',
  ],
  staging: [
    'https://takecareca.onmicrosoft.com/api-staging/takecare.read',
    'https://takecareca.onmicrosoft.com/api-staging/takecare.write',
  ],
  takecare: [
    'https://takecareca.onmicrosoft.com/api-prod/takecare.read',
    'https://takecareca.onmicrosoft.com/api-prod/takecare.write',
  ],
  hotfix: [
    'https://takecareca.onmicrosoft.com/api-prod/takecare.read',
    'https://takecareca.onmicrosoft.com/api-prod/takecare.write',
  ],
  localhost: [
    'https://takecareca.onmicrosoft.com/api/takecare.read',
    'https://takecareca.onmicrosoft.com/api/takecare.write',
  ],
};

const tenantGraphMeEndpoint = {
  dev: 'https://dev-ca.takecareapi.com/greybox-api/account/?self=true',
  localhost: 'https://dev-ca.takecareapi.com/greybox-api/account/?self=true',
  staging: 'https://staging-ca.takecareapi.com/greybox-api/account/?self=true',
  takecare: 'https://takecare.greybox.ca/greybox-api/account/?self=true',
  hotfix: 'https://takecare.greybox.ca/greybox-api/account/?self=true',
};

// App Insights Config
const tenantKeyConfig = {
  localhost: '54cd99c9-5e22-4741-b47d-04dc9ac958e2',
  'dev.greybox.ca': '54cd99c9-5e22-4741-b47d-04dc9ac958e2',
  'dev-ca.greybox.fr': '54cd99c9-5e22-4741-b47d-04dc9ac958e2',
  'dev.greybox.fr': '54cd99c9-5e22-4741-b47d-04dc9ac958e2',
  'staging.greybox.ca': '706e7f63-3aa3-42bb-ad81-4fca0d924b26',
  'staging.greybox.fr': '706e7f63-3aa3-42bb-ad81-4fca0d924b26',
  'takecare.greybox.ca': '908b13b8-2fee-4e9e-9769-13a658917c6a',
  'takecare.greybox.fr': '908b13b8-2fee-4e9e-9769-13a658917c6a',
};

const tenantHeaderConfig = {
  'dev.greybox.ca': 'dev-ca.takecareapi.com',
  'dev-ca.greybox.fr': 'dev-ca.takecareapi.com',
  'dev.greybox.fr': 'dev-ca.takecareapi.com',
  'staging.greybox.ca': 'staging-ca.takecareapi.com',
  'staging.greybox.fr': 'staging-ca.takecareapi.com',
  'takecare.greybox.ca': 'ca.takecareapi.com',
  'takecare.greybox.fr': 'ca.takecareapi.com',
  localhost: 'dev-ca.takecareapi.com',
};

// API Config
const tenantDomainConfigs = {
  localhost: 'dev-ca.takecareapi.com',
  'dev.greybox.ca': 'dev-ca.takecareapi.com',
  'dev-ca.greybox.fr': 'dev-ca.takecareapi.com',
  'dev.greybox.fr': 'dev-eu.takecareapi.com',
  'staging.greybox.ca': 'staging-ca.takecareapi.com',
  'hotfix.greybox.ca': 'ca.takecareapi.com',
  'staging.greybox.fr': 'staging-eu.takecareapi.com',
  'takecare.greybox.ca': 'ca.takecareapi.com',
  'takecare.greybox.fr': 'eu.takecareapi.com',
};

export {
  tenantEnvironmentIds,
  tenantAuthority,
  tenantScopes,
  tenantScopesToken,
  tenantGraphMeEndpoint,
  tenantKeyConfig,
  tenantHeaderConfig,
  tenantDomainConfigs,
};
