import { createTheme } from '@mui/material';
import getTheme from '../../js/themes/defaultTheme';

export const createGreyboxTheme = (style) => {
  const defaultTheme = getTheme(style);

  return createTheme({
    ...defaultTheme,
  });
};

const themeConfig = (style) => createGreyboxTheme(style);

export default themeConfig;
