import {
  Box, Paper, Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import 'moment/locale/fr';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Route, Routes
} from 'react-router-dom';
import AccountTable from '../components/accounts/AccountTable';
import CareTeam from '../components/care-team/CareTeam';
import CareTeamDetails from '../components/care-team/CareTeamDetails';
import ClinicAdminNavigation from '../components/clinic-admin/ClinicAdminNavigation';
import ClinicInformation from '../components/clinic-admin/ClinicInformation';
import InformationMessageTable from '../components/clinic-admin/InformationMessageTable';
import QuestionnaireEditTabs from '../components/clinic-admin/questionnaire/QuestionnaireEditTabs';
import Questionnaires from '../components/clinic-admin/questionnaire/Questionnaires';
import QuestionEdit from '../components/clinic-admin/questionnaire/questions/QuestionEdit';
import DeviceDetails from '../components/devices/DeviceDetails';
import DeviceTable from '../components/devices/DeviceTable';
import EducationPanel from '../components/clinic-admin/education/EducationPanel';
import MedicalActs from '../components/patient-profile/MedicalActs';
import WorkflowRoutes from '../components/workflow/WorkflowRoutes';

const ClinicSettings = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display={isMobile ? 'block' : 'flex'}>
      <ClinicAdminNavigation />
      <Routes>
        <Route path="care-team/:id" element={<CareTeamDetails />} />
        <Route path="questionnaires/:id" element={<QuestionnaireEditTabs />} />
        <Route path="questionnaires/:id/questions" element={<QuestionnaireEditTabs />} />
        <Route path="questionnaires/:id/questions/:questionId" element={<QuestionEdit />} />
        <Route
          path="device/:id"
          element={
            <Paper sx={{ p: 1, pt: 2 }}>
              <Typography variant="h5">{t('Edit Device Details')}</Typography>
              <DeviceDetails />
            </Paper>
          }
        />
        <Route path="accounts/:type" element={<AccountTable />} />
        <Route path="care-team" element={<CareTeam hasPatient={false}/>} />
        <Route path="medical-acts" element={<MedicalActs isAdmin />} />
        <Route path="documentation" element={<EducationPanel />} />
        <Route path="clinic-info" element={<Box width="100%">
                                              <ClinicInformation />
                                              <InformationMessageTable />
                                            </Box>} />
        <Route path="questionnaires" element={<Questionnaires />} />
        <Route path="device" element={<DeviceTable />} />
        <Route path="workflow/*" element={<WorkflowRoutes />} />
        <Route index element={<Navigate to="clinic-info" />} /> 
      </Routes>
    </Box>
  );
};

export default ClinicSettings;
