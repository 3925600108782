import {
  Box, Button, Fade, Slide, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'

const TIMEOUT = 900;

const QuestionnaireIntro = ({ questionnaire, setIsStarted }) => {
  const { t } = useTranslation();
  const sliderRef = React.useRef(null);

  return (
    <Box
      overflow="hidden"
      height="100%"
      ref={sliderRef}
      width="100%"
      display="flex"
      maxWidth="lg"
    >
      <Slide
        direction="up"
        in
        mountOnEnter
        container={sliderRef.current}
        unmountOnExit
        timeout={TIMEOUT}
      >
        <Box display="flex" justifyContent="center" width="100%">
          <Fade in timeout={TIMEOUT + 1000}>
            <Box
              width="60%"
              padding={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h4" align="center" gutterBottom>
                {questionnaire.name}
              </Typography>
              <Box fontFamily={(theme) => theme.typography.fontFamily}>
                <Markdown skipHtml remarkPlugins={[remarkGfm]}>
                  {questionnaire.description}
                </Markdown>
              </Box>
              <Box display="flex" alignItems="baseline">
                <Button
                  sx={{ mt: 2, mr: 1 }}
                  size="large"
                  variant="contained"
                  autoFocus
                  data-cy="start-questionnaire"
                  disableFocusRipple
                  onClick={() => setIsStarted(true)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                      setIsStarted(true);
                    }
                  }}
                  color="primary"
                >
                  {t('Start')}
                </Button>
              </Box>
            </Box>
          </Fade>
        </Box>
      </Slide>
    </Box>
  );
};

export default QuestionnaireIntro;
