import React, { createContext, useContext, useMemo } from 'react';
import themeConfig from 'themeConfig';

import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';

const ThemeContext = createContext();
const useThemeContext = () => useContext(ThemeContext);

const ThemeContextProvider = ({ children }) => {
  const { style } = useSelector((state) => state.theme);

  const theme = useMemo(() => {
    try {
      return themeConfig(style);
    } catch (error) {
      console.error("Error in theme selection:", error);
    }
  }, [style]);

  return (
    <ThemeContext.Provider value={{ theme }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContextProvider, useThemeContext };
