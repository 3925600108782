import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Skeleton,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  IoArrowBackOutline,
  IoCheckmarkDoneOutline,
  IoCloudDoneOutline,
  IoCloudOfflineOutline,
  IoCloudUploadOutline,
  IoDocumentTextOutline,
  IoMicOutline,
  IoPrintOutline,
  IoSaveOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import Markdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import remarkGfm from 'remark-gfm';
import * as yup from 'yup';
import { greyboxApiActions } from '../../../redux/api';
import AudioPickerDialog from '../../audio/AudioPickerDialog';
import FormikTextField from '../../form-inputs/FormikTextField';
import MarkdownEditor from '../components/MarkdownEditor';
import ConfirmationDialog from './ConfirmationDialog';
import GenerateClinicalNoteReportDialog from './GenerateClinicalNoteReportDialog';
import PrintableNote from './PrintableNote';

const useStyles = makeStyles((theme) => ({
  spacer: {
    marginBottom: theme.spacing(2),
  },
  editorContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: theme.spacing(2),
    minHeight: '100px',
    marginBottom: theme.spacing(2),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  readOnlySummary: {
    minHeight: '20px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
  },
  markdown: {
    padding: '0 18px',
    overflow: 'auto',
  },
  readOnlyLabel: {
    position: 'absolute',
    top: '-8px',
    left: '10px',
    backgroundColor: theme.palette.background.paper,
    padding: '0 4px',
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
  },
  readOnlyDescription: {
    minHeight: '50vh',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    overflow: 'auto',
    fontFamily: theme.typography.fontFamily,
  },
}));

const ClinicalNotePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid, clinicalNoteId } = useParams();
  const patientUuid = uuid;
  const classes = useStyles();
  const { clinicalImpression, transcribeAudio } = greyboxApiActions;
  const [updateImpression] = clinicalImpression.update();
  const [createImpression] = clinicalImpression.add();
  const [postTranscribeAudio] = transcribeAudio.add();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [currentDialog, setCurrentDialog] = useState('');
  const { accountId } = useSelector((state) => state.user);
  const [iframeUrl, setIframeUrl] = useState('');
  const [iframeOpen, setIframeOpen] = useState(false);
  const [documentToPrint, setDocumentToPrint] = useState(null);
  const [openGenerateClinicalReportDialog, setOpenGenerateClinicalReportDialog] = useState(false);
  const [openGeneratedReportMessage, setOpenGeneratedReportMessage] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: '', severity: 'success' });
  const [openAudioPicker, setOpenAudioPicker] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const printRef = useRef();
  const [isTranscribing, setIsTranscribing] = useState(false);

  const { data, isFetching, refetch } = clinicalImpression.get(clinicalNoteId, {
    skip: clinicalNoteId === 'create-new-note',
  });

  const isNewNote = clinicalNoteId === 'create-new-note';
  const firstName = data?.assessor?.display?.firstName;
  const lastName = data?.assessor?.display?.lastName;
  const formattedName = firstName ? (isMobile ? `${firstName.charAt(0).toUpperCase()}.` : firstName) : '';
  const lastUpdated = new Date(data?.meta?.lastUpdated).toLocaleString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  const handleGoBack = (dirty) => {
    if (data?.status === 'completed') {
      navigate(`/patient-profile/${patientUuid}/clinical-note`);
    } else if (dirty && !documentToPrint) {
      setConfirmationOpen(true);
      setCurrentDialog('leave');
    } else {
      navigate(`/patient-profile/${patientUuid}/clinical-note`);
    }
  };

  const handleOpenAudioPicker = () => {
    setIsWarningOpen(true);
  };

  const handleCloseAudioPicker = () => {
    setOpenAudioPicker(false);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: documentToPrint?.summary,
    onAfterPrint: () => setDocumentToPrint(null),
    ignoreGlobalStyles: true,
  });

  useEffect(() => {
    if (documentToPrint) {
      handlePrint();
    }
  }, [documentToPrint, handlePrint]);

  const handleWarningAccept = () => {
    setIsWarningOpen(false);
    setOpenAudioPicker(true);
  };

  const handleWarningClose = () => {
    setIsWarningOpen(false);
  };

  return (
    <Formik
      initialValues={{
        summary: data?.summary || '',
        description: data?.description || '',
        status: data?.status || 'in-progress',
      }}
      enableReinitialize
      validationSchema={yup.object({
        summary: yup.string().required(t('Summary is required')),
        description: yup.string().required(t('Description is required')),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const body = {
            ...values,
            assessor: {
              reference: `Practitioner/${accountId}`,
            },
          };

          if (isNewNote) {
            body.subject = { reference: `Patient/${patientUuid}` };
            const response = await createImpression({ body });
            const newNoteId = response.data.id;
            navigate(`/patient-profile/${patientUuid}/clinical-note/${newNoteId}`, { replace: true });
          } else {
            await updateImpression({ id: clinicalNoteId, body });
          }
        } catch (error) {
          console.error('Error saving note:', error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values, handleSubmit, submitForm, isSubmitting, dirty, isValid, setFieldValue }) => {
        const handleAudioSelected = async (audioBlob) => {
          handleCloseAudioPicker();
          const formData = new FormData();
          if (!values.summary) {
            formData.append('include_generated_title', String(true));
          }

          formData.append('audio_file', audioBlob, 'recording.wav');

          setIsTranscribing(true);
          try {
            const result = await postTranscribeAudio({ body: formData });
            if (result.data.generated_title) {
              setFieldValue('summary', result.data.generated_title);
            }
            setFieldValue('description', result.data.transcription);
          } catch (error) {
            console.error('Error during audio transcription:', error);
          } finally {
            setIsTranscribing(false);
          }
        };

        const debouncedSubmit = useCallback(
          debounce(() => {
            if (dirty && isValid) {
              submitForm();
            }
          }, 5000),
          [submitForm, dirty, isValid]
        );

        useEffect(() => {
          return () => {
            debouncedSubmit.cancel();
          };
        }, [debouncedSubmit]);

        useEffect(() => {
          debouncedSubmit();
        }, [debouncedSubmit, values.summary, values.description]);

        const handleFormAction = async (status) => {
          try {
            const body = {
              ...values,
              status: status
            };
            await updateImpression({ id: clinicalNoteId, body });
            if (status === 'entered-in-error') {
              navigate(-1);
            }
          } catch (error) {
            console.error(`Error during ${status} action:`, error);
          }
        };

        const handleConfirmDialog = async () => {
          switch (currentDialog) {
            case 'finalize':
              await handleFormAction('completed');
              break;
            case 'delete':
              if (isNewNote) {
                navigate(-1);
              } else {
                await handleFormAction('entered-in-error');
              }
              break;
            case 'leave':
              navigate(-1);
              break;
            default:
              break;
          }
          setConfirmationOpen(false);
        };

        return (
          <Container maxWidth="md">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Tooltip title={t('Go back')}>
                <IconButton onClick={() => handleGoBack(dirty)} sx={{ mr: 1 }}>
                  <IoArrowBackOutline size={24} />
                </IconButton>
              </Tooltip>
              {isFetching ? (
                <Skeleton variant="text" width="60%" />
              ) : (
                <Typography
                  variant="h6"
                  className={classes.title}
                  sx={{
                    fontSize: '1.25rem',
                    fontWeight: 'bold',
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  {isNewNote
                    ? t('Create note')
                    : data?.status === 'completed'
                    ? t('Clinical Note')
                    : t('Edit note')}
                </Typography>
              )}
              <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
                {data?.status !== 'completed' && (
                  <>
                    <Tooltip title={t('Transcribe Audio')}>
                      <IconButton onClick={handleOpenAudioPicker} aria-label="Transcribe Audio" disabled={isFetching}>
                        <IoMicOutline size={24} />
                      </IconButton>
                    </Tooltip>
                    {!isNewNote && (
                      <Tooltip title={t('Delete Draft')}>
                        <IconButton
                          onClick={() => {
                            setConfirmationOpen(true);
                            setCurrentDialog('delete');
                          }}
                          disabled={isFetching}
                          aria-label="Delete Draft"
                        >
                          <IoTrashOutline size={24} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title={t('Save Draft')}>
                      <IconButton
                        disabled={isSubmitting || isFetching}
                        aria-label="Save Draft"
                        onClick={submitForm}
                      >
                        {isSubmitting ? <CircularProgress size={24} /> : <IoSaveOutline size={24} />}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Finalize')}>
                      <IconButton
                        onClick={() => {
                          setConfirmationOpen(true);
                          setCurrentDialog('finalize');
                        }}
                        disabled={isSubmitting || isFetching}
                        aria-label="Finalize"
                      >
                        <IoCheckmarkDoneOutline size={24} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {data?.status === 'completed' && (
                  <>
                    <Tooltip title={t('Print Note')}>
                      <IconButton
                        onClick={() => setDocumentToPrint(data)}
                        disabled={isFetching}
                        aria-label="Print Note"
                      >
                        <IoPrintOutline size={24} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Generate Report')}>
                      <IconButton
                        onClick={() => setOpenGenerateClinicalReportDialog(true)}
                        disabled={isFetching}
                        aria-label="Generate Report"
                      >
                        <IoDocumentTextOutline size={24} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {isFetching ? (
                  <Skeleton variant="text" width="100%" />
                ) : (
                  <Tooltip
                    title={
                      formattedName && lastName && lastUpdated
                        ? t('Last save made by {{formattedName}} {{lastName}} at {{lastUpdated}}', {
                            formattedName,
                            lastName,
                            lastUpdated,
                          })
                        : t('The note is not saved')
                    }
                  >
                    <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
                      {isSubmitting ? (
                        <IoCloudUploadOutline color="grey" size={24} />
                      ) : dirty || isNewNote ? (
                        <IoCloudOfflineOutline color="grey" size={24} />
                      ) : (
                        <IoCloudDoneOutline color="green" size={24} />
                      )}
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </Box>
            <Paper className={classes.editorContainer}>
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  {isFetching ? (
                    <Skeleton variant="rectangular" height={56} width="100%" />
                  ) : !isNewNote && data?.status === 'completed' ? (
                    <div className={classes.readOnlySummary}>
                      <label className={classes.readOnlyLabel}>{t('Summary')}</label>
                      <Markdown className={classes.markdown}>{values.summary}</Markdown>
                    </div>
                  ) : (
                    <FormikTextField name="summary" label={t('Summary')} fullWidth required />
                  )}
                </Box>
                <div className={classes.spacer} style={{ position: 'relative' }}>
                  {isFetching ? (
                    <Skeleton variant="rectangular" height={420} width="100%" />
                  ) : !isNewNote && data?.status === 'completed' ? (
                    <div className={classes.readOnlyDescription} ref={printRef}>
                      <label className={classes.readOnlyLabel}>{t('Description')}</label>
                      <Markdown remarkPlugins={[remarkGfm]}>{data?.description}</Markdown>
                    </div>
                  ) : (
                    <Box sx={{ mb: 2 }}>
                      <MarkdownEditor name="description" />
                    </Box>
                  )}
                </div>
              </form>
            </Paper>
            <ConfirmationDialog
              isOpen={confirmationOpen}
              dialogActionType={currentDialog}
              onConfirm={handleConfirmDialog}
              onClose={() => setConfirmationOpen(false)}
            />
            <Box sx={{ display: 'none' }}>
              <PrintableNote ref={printRef} note={documentToPrint} />
            </Box>
            <Snackbar
              open={openGeneratedReportMessage}
              autoHideDuration={6000}
              onClose={() => setOpenGeneratedReportMessage(false)}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={() => setOpenGeneratedReportMessage(false)}
                severity={snackbar.severity}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
            <GenerateClinicalNoteReportDialog
              open={openGenerateClinicalReportDialog}
              handleClose={() => setOpenGenerateClinicalReportDialog(false)}
              resource={data}
              patientUuid={uuid}
              setOpenGeneratedReportMessage={setOpenGeneratedReportMessage}
              setSnackbar={setSnackbar}
              setIframeUrl={setIframeUrl}
            />
            <AudioPickerDialog
              open={openAudioPicker}
              onClose={handleCloseAudioPicker}
              onAudioSelected={handleAudioSelected}
            />
            <Dialog
              open={isWarningOpen}
              onClose={handleWarningClose}
              aria-labelledby="warning-dialog-title"
              aria-describedby="warning-dialog-description"
            >
              <DialogTitle id="warning-dialog-title">{t('Feature under development')}</DialogTitle>
              <DialogContent>
                <DialogContentText id="warning-dialog-description">
                  {t('The audio transcription feature is under development. Please carefully validate the entry before saving. Do you wish to continue?')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleWarningClose} color="primary">
                  {t('No')}
                </Button>
                <Button onClick={handleWarningAccept} color="primary" autoFocus>
                  {t('Yes')}
                </Button>
              </DialogActions>
            </Dialog>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isTranscribing}
            >
              <CircularProgress color="inherit" />
              <Typography variant="h6" sx={{ ml: 2 }}>
                {t('Transcribing audio, please wait...')}
              </Typography>
            </Backdrop>
          </Container>
        );
      }}
    </Formik>
  );
};

export default ClinicalNotePage;
